const entryPointRanking = `${process.env.VUE_APP_GBATTLE_API}gbattle-ranking/api/v1.0`;

export default {
  GET_LIST_EVENT: `${entryPointRanking}/user/event/list`,
  JOIN_EVENT: `${entryPointRanking}/user/join-event/:eventId`,
  REWARD_EVENT: `${entryPointRanking}/user/event/receive-reward/:eventId`,
  REWARD_MISSION: `${entryPointRanking}/user/event/mission/receive-reward/:eventId/:missionId`,
  GET_DETAIL_EVENT: `${entryPointRanking}/user/event/list-mission/:eventId`,
  CHOICE_MISSION: `${entryPointRanking}/user/choice-mission`,
  PERFORMING_MISSION: `${entryPointRanking}/user/mission-performing`,
}