/* eslint-disable */
import axios from 'axios'
import routers from '@/routers/routes'
import constants from '@/constants'

// storage
import { getToken, clearStorageWhenLogout } from "@/helpers/storage";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 300000
})

// request
instance.interceptors.request.use(async (config) => {
  config.headers.Authorization = 'Bearer ' + getToken()
  return config
})

// response
instance.interceptors.response.use(
  // handle success
  (response) => {
    return response
  },
  // handle error
  (error) => {
    const { response } = error;
    throw response
  }
)

export class BaseService {
  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request() {
    if (!window.navigator.onLine) {
      throw new Error("mất mạng")
    }
    return instance
  }

  // handle error common
  static handleError(error: any) {
    const errorCode = error?.data?.Data?.ErrorCode ?? error?.data?.Data?.error_code;
    if (errorCode == 403012 || errorCode == 400016) {
      clearStorageWhenLogout()
      routers.push(constants.LOGIN_PATH)
      return
    }
    throw errorCode
  }

  // handle success common
  static handleSuccess(response: any) {
    return response
  }
}