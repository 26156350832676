import { BaseService } from "@/services/baseService";
import Api from "@/services/api"

export class ChatService extends BaseService {

  static async getMessageIngame(id: string, createdAt: number) {
    try {
      const response = await this.request().get(Api.CHAT.GET_MESSAGE_INGAME.replace(':id', id), {
        params: {
          createdAt: createdAt
        }
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line
  static async sendMessageIngame(data: any) {
    try {
      const response = await this.request().post(Api.CHAT.SEND_MESSAGE_INGAME, data)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }
}