<template>
  <v-dialog v-model="dialogGiff" max-width="500" persistent>
    <div class="cl-dialog">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <div class="cl-title">{{ info.title }}</div>
      <img class="w-28 mb-6" :src="info.image" alt="" />
      <div class="text-customPurple cl-font-24-bold mb-2">{{ info.event }}</div>
      <div class="text-customPurple cl-font-18-bold">
        <span class="text-white">Bạn đã nhận </span>
        <span class="text-customYellow2">[ {{ info.name }} ]</span>
      </div>
      <v-btn class="cl-btn-accept" @click="onAccept()">
        <span class="cl-font-24-bold"> OK </span>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "dialogGiff",
  emit: ["closeDialog"],

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      dialogGiff: this.value,
    };
  },

  watch: {
    dialogGiff(val, oldVal) {
      if (val === oldVal) return;
    },
    value(val, oldVal) {
      if (val === oldVal) return;
      this.dialogGiff = val;
    },
  },

  methods: {
    onAccept() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-dialog {
  width: 500px;
  height: 450px;
  padding: 10px;
  .cl-title {
    position: absolute;
    top: 40px;
  }
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 250px;
    height: 60px;
    bottom: 40px;
    position: absolute;
  }
}
</style>