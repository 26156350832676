<template>
  <div class="cl-info-room noselect">
    <div class="cl-info-left">
      <!-- TODO: open -->
      <!-- <v-btn
        width="160"
        variant="outlined"
        :color="steamInfo?.steam_id ? '#FFFFFF' : '#00C2FF'"
        height="34"
        @click="steamInfo?.steam_id ? () => {} : onConnenctSteam()"
      >
        <span class="normal-case cl-font-14-bold maxline1 w-32">{{
          steamInfo?.steam_id
            ? steamInfo?.display_name
            : $t("room.btn_connect_steam")
        }}</span>
        <v-icon class="ml-1" size="24">mdi-steam</v-icon>
      </v-btn> -->
      <div class="cl-team cl-font-14-bold maxline1">
        <v-icon class="mt-n1">mdi-account-group-outline</v-icon>
        {{ infoParty?.party_name }}
      </div>
      <v-btn
        width="90"
        height="34"
        variant="outlined"
        color="#FF507A"
        @click="onLeaveTeam"
      >
        <span class="normal-case cl-font-14-normal text-customPink">
          {{ $t("room.btn_leave_room") }}
        </span>
        <img class="ml-2" src="@/assets/images/icons/leave.svg" alt="" />
      </v-btn>
      <!-- TODO: remove -->
      <div class="w-40"></div>
    </div>
    <div class="cl-flex-center">
      <div class="cl-info-center cl-font-14-bold">Competitive 5v5</div>
    </div>
    <div class="cl-info-left">
      <div
        class="cl-mission cl-font-14-bold"
        :style="{
          background: mission ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
          cursor: mission ? 'pointer' : '',
        }"
        @click.stop="goToEventDetail"
      >
        <div class="maxline1">{{ mission?.mission_info[0]?.mission_name || "" }}</div>
        <img class="w-6" :src="mission?.mission_info[0]?.mission_image" alt="" srcset="">
      </div>
      <v-btn
        width="180"
        height="34"
        variant="outlined"
        color="rgba(255, 255, 255, 0.2)"
        @click="onOpenDrawerFriend"
      >
        <div class="cl-btn-friend">
          <div class="cl-btn-friend-text cl-font-14-bold">
            <img class="mr-2" src="@/assets/images/icons/account.svg" alt="" />
            <div>{{ $t("room.btn_friends") }}</div>
          </div>
          <v-icon>mdi-arrow-left</v-icon>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import constants from "@/constants";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";
import { MissionService } from "@/modules/missions/services/missionServices";

// router
import routesPath from "@/routers/routesPath";
import routesNameMission from "@/modules/missions/routers/routesName";

// storage
import { removeInfoParty } from "@/helpers/storage";

export default {
  // mixins: [steamConnect],
  props: {
    infoParty: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const store = useStore(key);
    const popupSteam = ref(null);
    const mission = ref(null);
    const onOpenDrawerFriend = () => {
      store.dispatch("setDrawerFriend", true);
    };

    async function getMissionPerforming() {
      try {
        const res = await MissionService.missionPerforming();
        mission.value = res.Data;
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      getMissionPerforming();
    });

    return {
      mission,
      popupSteam,
      onOpenDrawerFriend,
    };
  },

  methods: {
    async onLeaveTeam() {
      try {
        await BattleService.leaveParty();
        removeInfoParty();
        this.$router.push(routesPath.G_BATTLE_CS2_PATH);
        this.$store.dispatch("setUpdateInfoParty", true);
      } catch (error) {
        // phong da bi xoa
        if (error === 4001501) {
          removeInfoParty();
          this.onBackGBattleCs2();
          return;
        }
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },

    onBackGBattleCs2() {
      this.$router.push(routesPath.G_BATTLE_CS2_PATH);
    },

    goToEventDetail() {
      if (!this.mission) {
        return;
      }
      this.$router.push({
        name: routesNameMission.EVENT_DETAIL,
        params: {
          eventId: this.mission.event_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-info-room {
  height: 80px;
  padding: 20px 6px 20px 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  .cl-flex-center {
    display: flex;
    flex: 1;
    justify-content: center;
    .cl-info-center {
      width: 196px;
      height: 34px;
      margin-left: -16px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 80, 122, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .cl-info-left {
    width: max-content;
    display: flex;
    gap: 20px;
    .cl-mission {
      width: 317px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 0;
      border-radius: 6px;
      padding: 0px 13px 0px 13px;
      color: rgba(255, 255, 255, 1);
    }
    // .cl-btn-text {
    //   text-transform: none;
    //   color: #00c2ff;
    // }
    .cl-btn-friend {
      width: 150px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      text-transform: none;
      text-align: left;
      color: #ffffff;
      .cl-btn-friend-text {
        display: flex;
        align-items: center;
        align-content: center;
      }
    }
    .cl-team {
      width: 224px;
      height: 34px;
      padding: 8px;
      align-content: center;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.1);
      font-family: Montserrat;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>