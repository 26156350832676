/* eslint-disable */
import { BaseService } from "@/services/baseService";
import Apis from "@/modules/profile/services/apis"

export class ProfileService extends BaseService {
  static async getTypeBox(typeBox: number) {
    try {
      const response = await this.request().get(Apis.GET_TYPE_BOX, {
        params: {
          typeBox: typeBox
        }
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async changeAvatar(itemId: number) {
    try {
      const response = await this.request().put(Apis.USING_AVATAR, {
        ItemId: itemId
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async changeFrame(itemId: number) {
    try {
      const response = await this.request().put(Apis.USING_FRAME, {
        ItemId: itemId
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async changeEffect(itemId: number) {
    try {
      const response = await this.request().put(Apis.USING_EFFECT, {
        ItemId: itemId
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async updateProfile(payload: any) {
    try {
      const response = await this.request().put(Apis.UPDATE_PROFILE, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async updateEmail(payload: any) {
    try {
      const response = await this.request().post(Apis.UPDATE_EMAIL, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }
}