/* eslint-disable */
import { App, inject as vueInject, InjectionKey } from 'vue';

interface MqttPlugin {
  on: (event: string, listener: (...args: any[]) => void) => void;
  off: (event: string, listener: (...args: any[]) => void) => void;
  subscribe: (topic: string, qos: number) => void;
  unsubscribe: (topic: string) => void;
  worker: Worker;
}

const mqttPlugin = {
  install(app: App) {
    if (typeof Worker !== 'undefined') {
      const VUE_APP_MQTT_URL: string = process.env.VUE_APP_MQTT_URL as string;
      const VUE_APP_MQTT_PORT: string = process.env.VUE_APP_MQTT_PORT as string;
      const VUE_APP_MQTT_USERNAME: string = process.env.VUE_APP_MQTT_USERNAME as string;
      const VUE_APP_MQTT_PASSWORD: string = process.env.VUE_APP_MQTT_PASSWORD as string;

      const worker = new Worker('/workers/mqttWorker.js');

      worker.postMessage({
        VUE_APP_MQTT_URL,
        VUE_APP_MQTT_PORT,
        VUE_APP_MQTT_USERNAME,
        VUE_APP_MQTT_PASSWORD
      });

      const eventListeners: { [key: string]: Array<(...args: any[]) => void> } = {};

      const on = (event: string, listener: (...args: any[]) => void): void => {
        if (!eventListeners[event]) {
          eventListeners[event] = [];
        }
        eventListeners[event].push(listener);
      };

      const off = (event: string, listener: (...args: any[]) => void): void => {
        if (!eventListeners[event]) return;
        eventListeners[event] = eventListeners[event].filter(l => l !== listener);
        eventListeners[event] = [];
      };

      const emit = (event: string, ...args: any[]): void => {
        if (eventListeners[event]) {
          eventListeners[event].forEach(listener => listener(...args));
        }
      };

      worker.onmessage = (event: MessageEvent) => {
        const { type, topic, message, error } = event.data;
        emit(type, topic, message, error);
      };

      const mqttClient: MqttPlugin = {
        on,
        off,
        subscribe: (topic: string, qos = 0): void => {
          worker.postMessage({ action: 'subscribe', topic, qos });
        },
        unsubscribe: (topic: string): void => {
          worker.postMessage({ action: 'unsubscribe', topic });
        },
        worker,
      };

      app.config.globalProperties.$clientMqtt = mqttClient;
      vueInject<MqttPlugin>(mqttClientKey, mqttClient);
    } else {
      console.warn('Web Workers are not supported in this browser.');
    }
  }
};

const mqttClientKey: InjectionKey<MqttPlugin> = Symbol('mqttClientKey');

export { mqttPlugin, mqttClientKey };
