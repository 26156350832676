<template>
  <div></div>
</template>

<script>
export default {
  name: "gbattle-shop",
};
</script>

<style>
</style>