<template>
  <div class="cl-live">
    <iframe v-bind:src="url" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script lang="ts">
// storage
import { getToken } from "@/helpers/storage";

export default {
  name: "liveModule",
  data() {
    return {
      url: `${
        process.env.VUE_APP_URL_GPLAY
      }/live?user-agent=GPlay-tournaments&token=${getToken()}`,
    };
  },
};
</script>

<style lang="scss" scoped>
.cl-live {
  width: 100%;
  height: 100vh;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>