const tokenKey = 'access_token'
export const getToken = () => localStorage.getItem(tokenKey)
export const setToken = (token: string) => localStorage.setItem(tokenKey, token)
export const removeToken = () => localStorage.removeItem(tokenKey)

const refreshTokenKey = 'refresh_token'
export const getRefreshToken = () => localStorage.getItem(refreshTokenKey)
export const setRefreshToken = (refeshToken: string) => localStorage.setItem(refreshTokenKey, refeshToken)
export const removeRefreshToken = () => localStorage.removeItem(refreshTokenKey)

const userInfo = 'user_info'
export const getUserInfo = () => localStorage.getItem(userInfo)
export const setUserInfo = (data: string) => localStorage.setItem(userInfo, data)
export const removeUserInfo = () => localStorage.removeItem(userInfo)

const saveAccountKey = 'save_account'
export const getSaveAccount = () => localStorage.getItem(saveAccountKey)
export const setSaveAccount = (save: string) => localStorage.setItem(saveAccountKey, save)
export const removeSaveAccount = () => localStorage.removeItem(saveAccountKey)

const userNameKey = 'user_name'
export const getUserName = () => localStorage.getItem(userNameKey)
export const setUserName = (userName: string) => localStorage.setItem(userNameKey, userName)
export const removeUserName = () => localStorage.removeItem(userNameKey)

const gShieldKey = 'g_shield'
export const getGshield = () => localStorage.getItem(gShieldKey)
export const setGshield = (show: string) => localStorage.setItem(gShieldKey, show)
export const removeGshield = () => localStorage.removeItem(gShieldKey)

const firstLoginKey = 'first_login'
export const getFirstLogin = () => localStorage.getItem(firstLoginKey)
export const setFirstLogin = (show: string) => localStorage.setItem(firstLoginKey, show)
export const removeFirstLogin = () => localStorage.removeItem(firstLoginKey)

function base64ToBytes(base64: string): Uint8Array {
  const binString = atob(base64);
  return Uint8Array.from(binString, (char) => char.codePointAt(0) as number);
}

function bytesToBase64(bytes: Uint8Array): string {
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte)
  ).join("");
  return btoa(binString);
}

const passwordKey = 'password'
export const getPassword = () => new TextDecoder().decode(base64ToBytes(localStorage.getItem(passwordKey) || ""))
export const setPassword = (password: string) => localStorage.setItem(passwordKey, bytesToBase64(new TextEncoder().encode(password)))
export const removePassword = () => localStorage.removeItem(passwordKey)

const infoPartyKey = 'info_party'
export const getInfoParty = () => localStorage.getItem(infoPartyKey)
export const setInfoParty = (data: string) => localStorage.setItem(infoPartyKey, data)
export const removeInfoParty = () => localStorage.removeItem(infoPartyKey)

export const clearStorageWhenLogout = () => {
  removeToken()
  removeUserInfo()
  removeRefreshToken()
  removeInfoParty()
  removeFirstLogin()
  const saveAccount = JSON.parse(getSaveAccount() || "false")
  if (!saveAccount) {
    removeUserName()
    removePassword()
  }
}