<template>
  <div class="cl-font-20-bold text-white">Trận đấu</div>
  <v-data-table
    :headers="headers"
    :items="dataTable"
    :loading="loading"
    items-per-page="-1"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-filter
    disable-sort
    color="transparent"
    density="comfortable"
    item-value="top"
    hide-default-footer
    @click:row="handleClickRow"
  >
    <template v-slot:[`item.map_name`]="{ item }">
      <div class="w-20 h-12 relative flex items-end justify-center my-2">
        <img
          class="cl-img-map absolute"
          :src="item.map_image"
          alt=""
          srcset=""
          style="z-index: -1"
        />
        <div class="cl-font-14-bold">
          {{ item.map_name }}
        </div>
      </div>
    </template>
    <template v-slot:[`item.created_time`]="{ item }">
      <div class="maxline1 text-customPurple cl-font-14-normal">
        {{ convertDate(item.created_time) }}
      </div>
    </template>
    <template v-slot:[`item.win_game`]="{ item }">
      <span
        :class="item.win_game == 1 ? 'text-customGreen2' : 'text-customOrange2'"
      >
        {{ item.win_game == 1 ? "W" : "L" }}
      </span>
    </template>
    <template v-slot:[`item.elo`]="{ item }">
      <span :class="item.elo >= 0 ? 'text-customYellow2' : 'text-customOrange'">
        {{ item.elo >= 0 ? `+${item.elo}` : item.elo }}</span
      >
    </template>
    <template v-slot:[`item.record_url`]="{ item }">
      <v-btn
        v-show="item.record_url"
        color="#7B72C6"
        icon
        variant="plain"
        @click.stop="downloadDemo(item.record_url)"
      >
        <v-icon size="18">mdi-tray-arrow-down</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { convertTextTime } from "@/helpers/utils";

// rounter
import routesPath from "@/modules/battleCS2/routers/routesPath";

export default {
  name: "matchingUser",

  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    userInfo: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        title: "Map",
        align: "center",
        sortable: false,
        key: "map_name",
      },
      { title: "Date", key: "created_time", sortable: false, align: "start" },
      { title: "W/L", key: "win_game", sortable: false, align: "start" },
      { title: "Kill", key: "kills", sortable: false, align: "start" },
      { title: "Death", key: "deaths", sortable: false, align: "start" },
      { title: "Assist", key: "assist", sortable: false, align: "start" },
      { title: "HS%", key: "headshot_ratio", sortable: false, align: "start" },
      { title: "HS", key: "headshot", sortable: false, align: "start" },
      {
        title: "K/D Ratio",
        key: "kill_death_ratio",
        sortable: false,
        align: "start",
      },
      {
        title: "K/R Ratio",
        key: "kill_round_ratio",
        sortable: false,
        align: "start",
      },
      { title: "ELO", key: "elo", sortable: false, align: "start" },
      { title: "Demo", key: "record_url", sortable: false, align: "start" },
    ],
  }),

  methods: {
    async handleClickRow(item, row) {
      try {
        this.$router.push({
          path: routesPath.HISTORY_BATTLE_PATH,
          query: {
            gameId: row.item.game_id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    convertDate(time) {
      if (!time) {
        return 0;
      }

      var date = new Date(time * 1000);

      var month = convertTextTime(date.getMonth() + 1);
      var day = convertTextTime(date.getDate());
      var hour = convertTextTime(date.getHours());
      var minute = convertTextTime(date.getMinutes());
      var second = convertTextTime(date.getSeconds());

      // TODO: get all Date
      // var dateTest = new Date(date.getFullYear(), date.getMonth(), 1);
      // var days = [];
      // while (dateTest.getMonth() === date.getMonth()) {
      //   days.push(dateTest.getDate());
      //   dateTest.setDate(dateTest.getDate() + 1);
      // }

      return `${day}/${month}-${hour}:${minute}:${second}`;
    },

    downloadDemo(link) {
      window.open(link, "_self");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-table {
  color: #fff !important;
  background: transparent !important;
  ::v-deep(hr) {
    display: none;
  }
}
.cl-img-map {
  filter: brightness(50%);
}
::v-deep(.v-data-table__td:first-child) {
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
}
::v-deep(.v-data-table__td:last-child) {
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
}
::v-deep(.v-data-table__td) {
  border: 0px !important;
}
::v-deep(.v-data-table__tr) {
  background: #7b72c614;
}
::v-deep(.v-data-table__th) {
  height: 34px !important;
  background: rgba(0, 0, 0, 0.3);
}
::v-deep(.v-data-table-header__content) {
  font-size: 14px;
  font-weight: 700;
}
::v-deep(.v-data-table__td) {
  font-size: 16px;
  font-weight: 700;
}
::v-deep(.v-data-table__tr:hover) {
  background: rgba(123, 114, 198, 0.2);
}
::v-deep(table) {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
}
</style>