<template>
  <div class="cl-bottom-room">
    <v-btn width="160" variant="outlined" color="#43F76E" height="34">
      <span class="cl-font-14-bold text-customGreen2 normal-case">
        {{ $t("room.btn_anti_cheat") }}
      </span>
      <v-icon class="ml-1" size="24">mdi-shield</v-icon>
    </v-btn>
    <div class="flex flex-1 justify-center gap-11 mt-n7">
      <div
        v-if="isLeader"
        class="cl-btn-play-now cl-btn-option cl-font-16-bold noselect"
        @click.stop="onOpenDialogOption"
      >
        <img class="cl-bkg" src="@/assets/images/btn/btn_option.svg" alt="" />
        <span class="ml-5"> {{ $t("room.btn_select_option") }} </span>
      </div>
      <v-btn
        v-if="isLeader"
        :disabled="snackbarFindMatch"
        variant="text"
        class="cl-btn-play-now cl-polygon text-white cl-font-32-bold noselect border-text-stroke"
        @click="onClickPlayNow"
      >
        <div class="cl-bkg">
          <img src="@/assets/images/btn/btn_play.svg" alt="" />
        </div>
        <div class="cl-btn-polygon">
          <v-skeleton-loader
            class="cursor-pointer"
            color="transparent"
            type="card"
          ></v-skeleton-loader>
        </div>
        {{ $t("room.btn_waiting") }}
      </v-btn>
      <div
        class="cl-btn-play-now cl-btn-option cl-font-16-bold noselect"
        @click="onClickFindTeam"
      >
        <img class="cl-bkg" src="@/assets/images/btn/btn_find.svg" alt="" />
        <span class="ml-5"> {{ $t("room.btn_find_team") }} </span>
      </div>
    </div>
    <BtnMessage :id="infoParty?.party_id" />
  </div>
  <DialogBan
    :showDialog="showDialogBan"
    :startTime="timeBan"
    :message="messageBan"
    @closeDialog="onCloseDialogBan"
  />
  <DialogOption
    :data="dataDialogOption"
    :loading="loading"
    :showDialog="showDialogOption"
    @acceptDialog="onAcceptDialog"
    @closeDialog="onCloseDialogOption"
  />
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import DialogBan from "@/components/core/dialog/Ban.vue";
import DialogOption from "@/components/core/dialog/Option.vue";
import BtnMessage from "@/components/core/BtnMessage.vue";
import constants from "@/constants";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";

// router
import routesNameCs2 from "@/modules/battleCS2/routers/routesName";
import routesName from "@/routers/routesName";

// storage
import { getUserInfo } from "@/helpers/storage";

export default {
  emits: ["updateInforParty"],
  components: {
    DialogBan,
    DialogOption,
    BtnMessage,
  },
  props: {
    infoParty: {
      type: Object,
      default: null,
    },
  },
  watch: {
    infoParty() {
      this.dataDialogOption = {
        game_name: this.infoParty?.game_name,
        party_mode: this.infoParty?.party_mode,
        party_name: this.infoParty?.party_name,
        level_min_rank_require: this.infoParty?.level_min_rank_require,
        level_max_rank_require: this.infoParty?.level_max_rank_require,
        vip_require: this.infoParty?.vip_require,
        public_party: this.infoParty?.public_party,
        party_code: this.infoParty?.party_code,
      };
    },
  },
  setup(props) {
    const store = useStore(key);
    const timeBan = ref(0);
    const loading = ref(false);
    const messageBan = ref("");
    const dataDialogOption = ref({});
    const userInfo = JSON.parse(getUserInfo());
    const showDialogBan = ref(false);
    const showDialogOption = ref(false);
    const snackbarFindMatch = computed(() => store.state.snackBarFindMatch);

    // check leader
    const isLeader = computed(() => {
      return Boolean(props.infoParty?.party_leader === userInfo?.user_id);
    });

    // dialog option
    function onOpenDialogOption() {
      showDialogOption.value = true;
    }

    function onCloseDialogOption() {
      showDialogOption.value = false;
    }

    // dialog ban
    function onOpenDialogBan() {
      showDialogBan.value = true;
    }

    function onCloseDialogBan() {
      showDialogBan.value = false;
    }

    return {
      loading,
      timeBan,
      messageBan,
      dataDialogOption,
      isLeader,
      showDialogBan,
      snackbarFindMatch,
      showDialogOption,
      onOpenDialogOption,
      onCloseDialogOption,
      onOpenDialogBan,
      onCloseDialogBan,
    };
  },
  methods: {
    async onClickPlayNow() {
      try {
        await BattleService.matchMaking();
      } catch (error) {
        // party khong ton tai
        if (error === 4001501) {
          this.onBackGBattleCs2();
          return;
        }
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },

    async onAcceptDialog(data) {
      try {
        this.loading = true;
        await BattleService.updateParty(data);
        this.onCloseDialogOption();
        this.$emit("updateInforParty", data);
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        this.loading = false;
      }
    },

    onBackGBattleCs2() {
      this.$router.push({
        name: routesName.G_BATTLE_CS2,
      });
    },

    onClickFindTeam() {
      this.$router.push({
        name: routesNameCs2.FIND_TEAM,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-bottom-room {
  height: 80px;
  padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: space-around;
  .cl-btn-play-now {
    width: 300px;
    height: 60px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    &:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
    .cl-bkg {
      width: 300px;
      z-index: -1;
      position: absolute;
    }
  }
  .cl-btn-option {
    width: 134px;
    position: relative;
    text-transform: none;
    color: rgba(0, 194, 255, 1);
    &:hover {
      color: rgba(0, 194, 255, 0.8);
    }
  }
}
.border-text-stroke {
  -webkit-text-stroke: 3px rgba(228, 164, 44, 1);
  paint-order: stroke fill;
}
.cl-btn-polygon {
  width: 300px;
  height: 60px;
  z-index: 10;
  top: 0px;
  right: 0px;
  overflow: hidden;
  position: absolute;
  clip-path: polygon(6% 0%, 94% 0%, 100% 50%, 94% 100%, 6% 100%, 0% 50%);
}
</style>