import Api from "@/services/api"
import eventPayload from "@/types/event";
import { BaseService } from "@/services/baseService";

export class EventService extends BaseService {
  static async postlogApp(payload: eventPayload) {
    try {
      const response = await this.request().post(Api.EVENT.LOG_APP, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }
}