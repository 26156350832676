<template>
  <DoughnutChart v-bind="doughnutChartProps" />
</template>

<script>
import { Chart, registerables } from "chart.js";
import { DoughnutChart, useDoughnutChart } from "vue-chart-3";
import { computed } from "vue";

Chart.register(...registerables);

export default {
  name: "doughnutChart",
  components: {
    DoughnutChart,
  },
  props: {
    labels: {
      type: Array,
      default: () => ["W", "T", "L"],
    },
    data: {
      type: Array,
      default: () => [0, 0, 0],
    },
    backgroundColor: {
      type: Array,
      default: () => ["#7B72C6", "#FFFFFF", "#FFFFFF"],
    },
  },
  setup(props) {
    const chartData = computed(() => ({
      labels: props.labels,
      datasets: [
        {
          // fill: true,
          data: props.data,
          borderWidth: 0,
          backgroundColor: props.backgroundColor,
        },
      ],
    }));
    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData,
      options: {
        responsive: true,
        cutout: 60,
        plugins: {
          tooltip: {
            enabled: true,
          },
          legend: {
            display: false,
          },
        },
      },
    });
    return { doughnutChartProps, doughnutChartRef };
  },
};
</script>
