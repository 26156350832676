<template>
  <div class="cl-battle-room">
    <InfoRoom ref="infoRoom" :info-party="infoParty" />
    <MemberInfo ref="memberInfo" :info-party="infoParty" />
    <ActionRoom
      :info-party="infoParty"
      @updateInforParty="onUpdateInforParty"
    />
    <DrawerChat :id="infoParty?.party_id" />
  </div>
</template>

<script>
import constants from "@/constants";

// component
import MemberInfo from "@/modules/battleCS2/view/components/MemberInfo.vue";
import InfoRoom from "@/modules/battleCS2/view/components/InfoRoom.vue";
import ActionRoom from "@/modules/battleCS2/view/components/ActionRoom.vue";
import DrawerChat from "@/components/DrawerChat.vue";

// storage
import { setInfoParty, getUserInfo } from "@/helpers/storage";

// mixins
import Cs2Party from "@/modules/battleCS2/mixins/party";

// rounter
import routesPath from "@/routers/routesPath";

export default {
  name: "roomBattle",
  mixins: [Cs2Party],

  components: {
    MemberInfo,
    InfoRoom,
    ActionRoom,
    DrawerChat,
  },

  data() {
    return {
      userInfo: null,
      actionMqtt: [
        {
          action: "joining",
          handleAction: (val) => this.handleJoining(val),
        },
        {
          action: "kick_member",
          handleAction: (val) => this.handleKickMember(val),
        },
        {
          action: "leaving",
          handleAction: (val) => this.handleLeaving(val),
        },
        {
          action: "update_leader",
          handleAction: (val) => this.handleUpdateLeader(val),
        },
        {
          action: "making_penalty",
          handleAction: (val) => this.handleMakingPenalty(val),
        },
        {
          action: "link_steam",
          handleAction: (val) => this.handleLinkSteam(val),
        },
      ],
    };
  },

  async mounted() {
    this.userInfo = JSON.parse(getUserInfo());
    await this.getInfoParty();
    this.onListenMessageMqtt();
  },

  beforeUnmount() {
    this.$store.dispatch("setDrawerChat", false);
  },

  methods: {
    onUpdateInforParty(data) {
      for (const key in data) {
        this.infoParty[key] = data[key];
      }
      setInfoParty(JSON.stringify(this.infoParty));
    },

    handleJoining(data) {
      this.infoParty?.members.push(data);
      if (this.$refs.memberInfo) {
        this.$refs.memberInfo.updateMember(data);
      }
    },

    async handleKickMember(data) {
      try {
        await this.handleLeaving(data);
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.$store.dispatch("setSnackBar", {
            show: true,
            message:
              this.userInfo.user_id === data.user_uuid
                ? "Bạn đã bị đuổi khỏi phòng"
                : `Người chơi ${data.name} đã bị đuổi khỏi phòng`,
            type: constants.TYPE_SNACK_BAR.WARNING,
          });
        }, 200);
      }
    },

    handleLeaving(data) {
      const indexLeaving = this.infoParty?.members.findIndex(
        (it) => it.user_uuid === data.user_uuid
      );
      if (indexLeaving >= 0) {
        if (
          this.userInfo.user_id ===
          this.infoParty?.members[indexLeaving].user_uuid
        ) {
          this.$router.push(routesPath.G_BATTLE_CS2_PATH);
          return;
        }
        this.infoParty?.members.splice(indexLeaving, 1);
        if (this.$refs.memberInfo) {
          this.$refs.memberInfo.updateListMember(this.infoParty?.members);
        }
      }
    },

    handleUpdateLeader(data) {
      const indexLeader = this.infoParty?.members.findIndex(
        (it) => it.user_uuid === data.user_uuid
      );
      if (indexLeader >= 0) {
        this.infoParty.members[indexLeader] = data;
        this.infoParty.party_leader = data.user_uuid;
        if (this.$refs.memberInfo) {
          this.$refs.memberInfo.updateLeader(data);
        }
      }
    },

    handleLinkSteam(data) {
      if (this.$refs.memberInfo) {
        this.$refs.memberInfo.updateSteam(data);
      }
      // if (this.$refs.infoRoom && this.userInfo.user_id == data.user_id) {
      //   this.$refs.infoRoom.onSetSteamInfo(data);
      // }
    },

    handleMakingPenalty(data) {
      if (!data || !data.length) {
        return;
      }
      data.forEach((item) => {
        const indexMember = this.infoParty?.members.findIndex(
          (it) => it.user_uuid === item.user_uuid
        );
        if (indexMember >= 0) {
          this.infoParty.members[indexMember].penalty_period_time =
            item.penalty_times;
        }
      });
    },

    // MQTT
    onListenMessageMqtt() {
      const topicParty = `${process.env.VUE_APP_TOPIC_PARTY}${this.infoParty?.party_id}`;
      this.$clientMqtt.on("message", async (topic, message) => {
        if (topicParty != topic) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        let { Action, Data } = JSON.parse(decoder.decode(message));
        console.log(Action);
        console.log(Data);
        const actionMqtt = this.actionMqtt.find((it) => it.action === Action);
        if (actionMqtt) {
          actionMqtt.handleAction(Data);
          setInfoParty(JSON.stringify(this.infoParty));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-battle-room {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
</style>