import routesName from './routesName';
import routesPath from './routesPath';

// component
import EventDetail from '@/modules/missions/view/eventDetail.vue';

export const routersMission = [
  {
    name: routesName.EVENT_DETAIL,
    path: routesPath.EVENT_DETAIL_PATH,
    component: EventDetail
  },
]