import { createI18n } from 'vue-i18n';

const messages = {
  vi: {
    ...require("@/locales/vi.json"),
    ...require("@/locales/battleCS2/vi.json"),
    ...require("@/locales/missions/vi.json")
  },
  en: {
    ...require("@/locales/en.json"),
    ...require("@/locales/battleCS2/en.json"),
    ...require("@/locales/missions/en.json")
  }
};

const localLang = 'vi'
const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || localLang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || localLang,
  messages
});

export default i18n;
