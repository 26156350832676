import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router';
import constants from '@/constants'
import routesName from './routesName';
import routesPath from './routesPath';

// router component
import { routersBattle } from '@/modules/battleCS2/routers/routes';
import { routersProfile } from '@/modules/profile/routers/routes';

// TODO: events
import { routersMission } from '@/modules/missions/routers/routes';

// component
import GbattleCS2 from '@/modules/battleCS2/index.vue';
import Tournaments from '@/modules/tournaments/index.vue';
import Live from '@/modules/live/index.vue';

// TODO: events
import Events from '@/modules/missions/index.vue';

// TODO: shop
import Shop from '@/modules/shop/index.vue';

// TODO: vip
// import GBattleVip from '@/modules/vip/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: constants.LOGIN_PATH,
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: constants.REGISTER_PATH,
    component: () => import('@/views/register/index.vue'),
  },
  {
    path: constants.ROOT_PATH,
    component: () => import('@/views/layout/default.vue'),
    children: [
      {
        name: 'home',
        path: '/',
        component: {},
        beforeEnter: (to, from, next) => {
          next(routesPath.G_BATTLE_CS2_PATH);
        }
      },
      {
        name: routesName.G_BATTLE_CS2,
        path: routesPath.G_BATTLE_CS2_PATH,
        component: GbattleCS2
      },
      {
        name: routesName.TOURNAMENT,
        path: routesPath.TOURNAMENT_PATH,
        component: Tournaments
      },
      {
        name: routesName.LIVE,
        path: routesPath.LIVE_PATH,
        component: Live
      },
      {
        name: routesName.EVENTS,
        path: routesPath.EVENTS_PATH,
        component: Events
      },
      {
        name: routesName.SHOP,
        path: routesPath.SHOP_PATH,
        component: Shop
      },
      // {
      //   name: routesName.G_BATTLE_VIP,
      //   path: routesPath.G_BATTLE_VIP_PATH,
      //   component: GBattleVip
      // },
      ...routersBattle,
      ...routersProfile,
      ...routersMission
    ]
  },
  // Add more routes here
];

const routers = createRouter({
  history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),
  routes
});

export default routers