<template>
  <v-dialog v-model="dialogBan" max-width="800" persistent>
    <div class="cl-dialog">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <div class="cl-title">{{ $t("dialog_ban.title") }}</div>
      <img class="mt-8" src="@/assets/images/icons/ban.svg" alt="" />
      <div class="cl-message cl-font-18-bold">
        {{ message }}
      </div>
      <v-btn class="cl-btn-accept mt-8" @click="onAccept">
        {{ formattedTime }}”
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { computed, ref, watch } from "vue";

// join game steam
import { formattedTimeCountDown } from "@/helpers/utils";

export default {
  name: "dialogBan",
  emit: ["closeDialog"],
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    startTime: {
      type: Number,
      required: false,
      default: 5, //s
    },
  },
  setup(props, { emit }) {
    // countdown
    const timeLeft = ref(0);
    const intervalId = ref(null);

    const dialogBan = ref(props.showDialog);
    watch(
      () => props.showDialog,
      () => {
        dialogBan.value = props.showDialog;
        if (dialogBan.value) {
          timeLeft.value = props.startTime;
          intervalId.value = setInterval(() => {
            if (timeLeft.value > 0) {
              timeLeft.value -= 1;
              return;
            }
            onCloseDialog();
          }, 1000);
        }
      }
    );
    // computed
    const formattedTime = computed(() => {
      return formattedTimeCountDown(JSON.parse(JSON.stringify(timeLeft.value)));
    });

    // function close dialog
    const onCloseDialog = () => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
      emit('closeDialog')
    };

    return {
      dialogBan,
      formattedTime,
      onCloseDialog,
    };
  },
  methods: {
    onAccept() {
      this.onCloseDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-dialog {
  padding: 41px 0px 50px 0px;
  .cl-message {
    flex: 1;
    margin-top: 64px;
    color: rgba(255, 255, 255, 1);
  }
}
</style>