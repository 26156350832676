import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    getBgImageTop(top: number) {
      switch (top) {
        case 1:
          return require("@/assets/images/tops/first.svg");
        case 2:
          return require("@/assets/images/tops/second.svg");
        case 3:
          return require("@/assets/images/tops/third.svg");
        case 4:
          return require("@/assets/images/tops/fourth.svg");
        case 5:
          return require("@/assets/images/tops/fifth.svg");
        default:
          return require("@/assets/images/tops/stt.svg");
      }
    },
    getBgImageRank(rank: number) {
      switch (true) {
        case (rank < 501):
          return require("@/assets/images/ranks/fighting_rooster.svg");
        case (rank < 751):
          return require("@/assets/images/ranks/rabbit.svg");
        case (rank < 901):
          return require("@/assets/images/ranks/hare.svg");
        case (rank < 1051):
          return require("@/assets/images/ranks/snake.svg");
        case (rank < 1201):
          return require("@/assets/images/ranks/snake_master.svg");
        case (rank < 1351):
          return require("@/assets/images/ranks/fox.svg");
        case (rank < 1531):
          return require("@/assets/images/ranks/fox_master.svg");
        case (rank < 1751):
          return require("@/assets/images/ranks/eagle.svg");
        case (rank <= 2000):
          return require("@/assets/images/ranks/eagle_master.svg");
        case (rank > 2000):
          return require("@/assets/images/ranks/dragon.svg");
        default:
          return require("@/assets/images/ranks/fighting_rooster.svg");
      }
    },
    getColorRank(rank: number) {
      switch (true) {
        case (rank < 501):
          return "#FFFFFF";
        case (rank < 751):
          return "#B7FFFB";
        case (rank < 901):
          return "#D1FFAC";
        case (rank < 1051):
          return "#5AB0FF";
        case (rank < 1201):
          return "#D06CFF";
        case (rank < 1351):
          return "#9E51FF";
        case (rank < 1531):
          return "#F24AFE";
        case (rank < 1751):
          return "#FFB570";
        case (rank <= 2000):
          return "#FF6B6A";
        case (rank > 2000):
          return "#FFFF00";
        default:
          return "#FFFFFF";
      }
    },
  }
})