const entryPoint = 'gamification/api/v2.0/user'
const entryUsers = 'users/api/v2.0'

export default {
  GET_TYPE_BOX: `${entryPoint}/vouchers`,
  USING_AVATAR: `${entryPoint}/avatar`,
  USING_FRAME: `${entryPoint}/frame`,
  USING_EFFECT: `${entryPoint}/effect`,
  UPDATE_PROFILE: `${entryUsers}/profile`,
  UPDATE_EMAIL: `${entryUsers}/account/email/update`
}