import { BaseService } from "@/services/baseService";
import Apis from "@/modules/battleCS2/services/apis"
import createPartyPayload from "../types/party";
import updatePartyPayload from "../types/party";
import joinPartyPayload from "../types/party";
import inviteMemberPayload from "../types/party";
import joinLobbyPayload from "../types/lobby";
import banPickPayload from "../types/lobby";
import statisticsParams from "../types/ranking";
import reportPayload from "../types/ranking";

export class BattleService extends BaseService {
  static async getSteamConfig() {
    try {
      const response = await this.request().get(Apis.STEAM_CONFIG)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getSteamAccount() {
    try {
      const response = await this.request().get(Apis.STEAM_ACCOUNT)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line
  static async verifierSteam(payload: any) {
    try {
      const response = await this.request().post(Apis.STEAM_VERIFIER, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  // party
  static async createParty(payload: createPartyPayload) {
    try {
      const response = await this.request().post(Apis.CREATE_PARTY, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async updateParty(payload: updatePartyPayload) {
    try {
      const response = await this.request().put(Apis.UPDATE_PARTY, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getInfoParty(payload: joinPartyPayload) {
    try {
      const response = await this.request().post(Apis.GET_INFO_PARTY, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async joinParty(payload: joinPartyPayload) {
    try {
      const response = await this.request().post(Apis.JOIN_PARTY, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async leaveParty() {
    try {
      const response = await this.request().delete(Apis.LEAVE_PARTY)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async inviteMember(payload: inviteMemberPayload) {
    try {
      const response = await this.request().post(Apis.INVITE_MEMBER, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async kickMember(payload: inviteMemberPayload) {
    try {
      const response = await this.request().post(Apis.KICK_MEMBER, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async acceptInvite(payload: inviteMemberPayload) {
    try {
      const response = await this.request().post(Apis.ACCEPT_INVITE, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async rejectInvite(payload: inviteMemberPayload) {
    try {
      const response = await this.request().delete(Apis.REJECT_INVITE, {
        params: payload
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async matchMaking() {
    try {
      const response = await this.request().post(Apis.MATCH_MAKING)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async acceptMatchMaking() {
    try {
      const response = await this.request().post(Apis.ACCEPT_MATCH_MAKING)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async cancelMatchMaking() {
    try {
      const response = await this.request().delete(Apis.CANCEL_MATCH_MAKING)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getLisPublicParty() {
    try {
      const response = await this.request().get(Apis.LIST_PUBLIC_PARTY)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async joinLobby(gameCode: string, payload: joinLobbyPayload) {
    try {
      const response = await this.request().put(Apis.JOIN_LOBBY.replace(':game_code', gameCode), payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getLobbyInfo(gameCode: string, lobbyId: string) {
    try {
      const response = await this.request().get(Apis.GET_LOBBY_INFO.replace(':game_code', gameCode).replace(':lobby_id', lobbyId))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getHistoryMatch(gameId: string) {
    try {
      const response = await this.request().get(Apis.GET_HISTORY_MATCH.replace(':game_id', gameId))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getCurrentMatch(gameCode: string, lobbyId: string) {
    try {
      const response = await this.request().get(Apis.GET_CURRENT_MATCH.replace(':game_code', gameCode).replace(':lobby_id', lobbyId))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async startGame() {
    try {
      const response = await this.request().post(Apis.START_GAME)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async banPickMap(data: banPickPayload, gameCode: string, matchId: string) {
    try {
      const response = await this.request().put(Apis.BAN_PICK_MAP_LOBBY.replace(':game_code', gameCode).replace(':match_id', matchId), data)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getRanking(gameCode: string, params: statisticsParams) {
    try {
      const response = await this.request().get(Apis.GET_RANKING.replace(':game_code', gameCode), {
        params: params
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getUserHistory(gameCode: string, params: statisticsParams) {
    try {
      const response = await this.request().get(Apis.GET_HISTORY.replace(':game_code', gameCode), {
        params: params
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async reportUser(payload: reportPayload) {
    try {
      const response = await this.request().post(Apis.REPORT_USER, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getBanbers(pos: string) {
    try {
      const response = await this.request().get(Apis.GET_BANNERS.replace(':pos', pos))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getListSeason(gameCode: string) {
    try {
      const response = await this.request().get(Apis.GET_LIST_SEASON.replace(':gameCode', gameCode))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }
}