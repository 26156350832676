<template>
  <div class="cl-inventory noselect flex h-max mx-20 my-3">
    <v-list lines="two">
      <v-list-item
        v-for="(item, index) in lstItem"
        :key="index"
        class="hover:bg-customPurple4"
        @click="onHandleActiveItem(item)"
      >
        <template v-slot:title>
          <span
            class="cl-font-16-bold uppercase"
            :class="
              itemActive?.id === item.id ? 'text-white' : 'text-customPurple'
            "
          >
            {{ item.title }}
          </span>
        </template>
      </v-list-item>
    </v-list>
    <div class="flex-1 cl-components">
      <Transition mode="out-in" name="slide-fade">
        <component :is="itemActive?.component" :data="itemActive?.data" />
      </Transition>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import constants from "@/constants";

// components
import Items from "./components/Items.vue";

// services
import { ProfileService } from "@/modules/profile/services/profileService";

export default {
  name: "InventoryVue",

  components: {
    Items,
  },

  setup() {
    const itemActive = ref(null);
    const lstItem = ref([
      {
        id: 1,
        title: "Vật phẩm",
        data: [],
        getData: () => {
          console.log("Vật phẩm");
        },
      },
      {
        id: 2,
        title: "Cá nhân",
        data: [],
        getData: () => getItems(),
        component: Items,
      },
    ]);

    function onHandleActiveItem(item) {
      item.getData();
      itemActive.value = item;
    }

    async function getItems() {
      try {
        const res = await ProfileService.getTypeBox(constants.TYPE_BOX.profile);
        itemActive.value.data = res.Data;
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      lstItem.value[1].getData();
      itemActive.value = lstItem.value[1];
    });

    return {
      lstItem,
      itemActive,
      onHandleActiveItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.cl-inventory {
  height: calc(100vh - 350px);
  overflow: hidden;
  .cl-components {
    min-width: 990px;
    padding-right: 5px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .v-list {
    width: 200px;
    min-width: max-content;
    height: max-content;
    color: #fff;
    margin-right: 20px;
    border-radius: 10px;
    border: none !important;
    margin-top: 0px !important;
    background: rgba(123, 114, 198, 0.08) !important;
  }
}
</style>