import routesName from './routesName';
import routesPath from './routesPath';

// component
import Room from '@/modules/battleCS2/view/room.vue';
import Lobby from '@/modules/battleCS2/view/lobby.vue';
import FindTeam from '@/modules/battleCS2/view/findTeam.vue';
import Ranking from '@/modules/battleCS2/view/ranking.vue';

export const routersBattle = [
  {
    name: routesName.ROOM_BATTLE,
    path: routesPath.ROOM_BATTLE_PATH,
    component: Room
  },
  {
    name: routesName.LOBBY_BATTLE,
    path: routesPath.LOBBY_BATTLE_PATH,
    component: Lobby
  },
  {
    name: routesName.FIND_TEAM,
    path: routesPath.FIND_TEAM_PATH,
    component: FindTeam
  },
  {
    name: routesName.RANKING,
    path: routesPath.RANKING,
    component: Ranking
  },
  {
    name: routesName.HISTORY_BATTLE,
    path: routesPath.HISTORY_BATTLE_PATH,
    component: Lobby
  },
]