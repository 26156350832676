const entryPointUser           = `users/api/v2.0`;
const entryPointChat           = `chat-v2/api/v2.1`;
const entryPointGbattle        = `gbattle-ranking/api/v1.0`;
const entryPointFriends        = `friends/api/v2.0`;
const entryPointEvent          = `${process.env.VUE_APP_EVENT_API}event/api/v1.0`;

export default {
  APP: {
    LOGIN: `${entryPointUser}/account/login/web-gb`,
    LOGOUT: `${entryPointUser}/account/logout/web`,
    CAPTCHA: `${entryPointUser}/account/captcha`,
    REGISTER: `${entryPointUser}/account/register-gb`,
    USER_INFO: `${entryPointGbattle}/statistics/cs2/user`,
    FRIENDS: `${entryPointFriends}/all/-1`,
    FRIENDS_PENDING: `${entryPointFriends}/pending`,
    SEARCH_FRIENDS: `${entryPointFriends}/users/search`,
    ADD_FRIEND: `${entryPointFriends}/add`,
    RELATION_SHIP_FRIEND: `${entryPointFriends}/update/relationship`,
  },
  CHAT: {
    GET_MESSAGE_INGAME: `${entryPointChat}/inGameChats/:id/messages`,
    SEND_MESSAGE_INGAME: `${entryPointChat}/messages`
  },
  EVENT: {
    LOG_APP: `${entryPointEvent}/user/log-app`
  }
}