
import constants from "@/constants";
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { BattleService } from "@/modules/battleCS2/services/battleServices";
import { getToken } from "@/helpers/storage";
import { useStore } from "vuex";
import { key } from "@/store";
import { useI18n } from "vue-i18n";
// import routesPathCs2 from "@/modules/battleCS2/routers/routesPath";


interface dataSteam {
  [key: string]: string;
}

export default defineComponent({
  data() {
    return {
      // eslint-disable-next-line
      store: null as any,
      // eslint-disable-next-line
      route: null as any,
      // eslint-disable-next-line
      router: null as any,
      // eslint-disable-next-line
      steamInfo: null as any,
      // eslint-disable-next-line
      t: null as any,
    }
  },

  created() {
    this.route = useRoute()
    this.router = useRouter()
    this.store = useStore(key)
    const { t } = useI18n()
    this.t = t
  },

  methods: {
    async showProfileSteam(steamid: number) {
      try {
        const link = `https://steamcommunity.com/profiles/${steamid}`
        window.open(link, "GBattle", 'popup');
      } catch (error) {
        console.log(error);
      }
    },

    async getSteamAccount() {
      try {
        if (Object.keys(this.route.query).length && this.route.query["openid.assoc_handle"]) {
          const data = {
            openid_assoc_handle: this.route.query["openid.assoc_handle"],
            openid_signed: this.route.query["openid.signed"],
            openid_sig: this.route.query["openid.sig"],
            openid_claimed_id: this.route.query["openid.claimed_id"],
            openid_identity: this.route.query["openid.identity"],
            openid_return_to: this.route.query["openid.return_to"],
            openid_response_nonce: this.route.query["openid.response_nonce"],
          } as dataSteam;
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              if (data[key]) {
                data[key] = data[key].replace(/\s/g, "");
              }
            }
          }
          await BattleService.verifierSteam(data);
        }
        const res = await BattleService.getSteamAccount();
        this.steamInfo = res.Data;
      } catch (error) {
        this.store.dispatch("setSnackBar", {
          show: true,
          message: this.t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        // eslint-disable-next-line
        this.router.replace({ query: null as any });
      }
    },

    async onConnenctSteam() {
      try {
        const res = await BattleService.getSteamConfig();
        const steamConfig = res.Data;
        let currentPath = window.location.href;
        if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
          currentPath = `${process.env.VUE_APP_GBATTLE}${this.route.path}`
        }
        const url =
          `${steamConfig.openid_op_endpoint}?` +
          `openid.claimed_id=${steamConfig.openid_claimed_id}&` +
          `openid.identity=${steamConfig.openid_identity}&` +
          `openid.mode=${steamConfig.openid_mode}&` +
          `openid.ns=${steamConfig.openid_ns}&` +
          `openid.realm=${currentPath}&` +
          `openid.return_to=${currentPath}${this.getTokenApp()}`;
        if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
          console.log(url);
          // eslint-disable-next-line
          (window as any).globalwindowSteam = window.open(url, "GBattle", 'popup');
        } else {
          window.location.href = url;
        }
      } catch (error) {
        console.log(error);
      }
    },

    getTokenApp() {
      return process.env.VUE_APP_BUILD === "DESKTOP-APP" ? `?token=${getToken()}` : ''
    },

    joinGameSteam(host: string, port: string, password: string) {
      window.location.href =
        `steam://connect/${host}:${port}/${password}`;
    }
  }
})