<template>
  <v-btn
    width="160"
    variant="outlined"
    color="#ffffff"
    height="34"
    @click="onOpenDrawerChat"
  >
    <div v-show="display" class="cl-btn-message">
      <span id="id-text" class="cl-btn-text cl-font-14-bold">{{
        $t("room.message")
      }}</span>
      <v-icon color="#ffffff" class="ml-1" size="24">
        mdi-message-reply-text-outline
      </v-icon>
    </div>
  </v-btn>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import { ref } from "vue";

export default {
  props: {
    id: {
      typeof: String,
      default: null,
    },
  },

  setup() {
    const display = ref(true);
    const interval = ref(null);
    const store = useStore(key);

    const onOpenDrawerChat = () => {
      store.dispatch("setDrawerChat", true);
    };
    const drawerChat = computed(() => store.state.drawerChat);

    return {
      display,
      interval,
      drawerChat,
      onOpenDrawerChat,
    };
  },

  mounted() {
    if (this.id) {
      this.onListenMessageMqtt();
    }
  },

  watch: {
    id() {
      if (this.id) {
        this.onListenMessageMqtt();
      }
    },
    drawerChat() {
      if (this.drawerChat) {
        this.display = true;
        clearInterval(this.interval);
      }
    },
    "$route.path"() {
      this.display = true;
      clearInterval(this.interval);
    },
  },

  methods: {
    onListenMessageMqtt() {
      const topicChat = `${process.env.VUE_APP_TOPIC_CHAT}/${this.id}`;
      this.$clientMqtt.on("message", async (topic) => {
        if (topicChat != topic) {
          return;
        }
        if (!this.drawerChat) {
          clearInterval(this.interval)
          this.interval = setInterval(() => {
            this.display = !this.display;
          }, 500);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-btn-message {
  display: flex;
  width: 140px;
  align-items: center;
  justify-content: space-between;
  .cl-btn-text {
    text-transform: none;
    color: rgba(0, 194, 255, 1);
  }
}
</style>
