import { BaseService } from "@/services/baseService";
import Apis from "@/modules/missions/services/api"

export class MissionService extends BaseService {
  static async getListEvent(timeState: number) {
    try {
      const response = await this.request().get(Apis.GET_LIST_EVENT, {
        params: {
          time_state: timeState
        }
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getDetailEvent(eventId: string) {
    try {
      const response = await this.request().get(Apis.GET_DETAIL_EVENT.replace(":eventId", eventId))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async joinEvent(eventId: string) {
    try {
      const response = await this.request().get(Apis.JOIN_EVENT.replace(":eventId", eventId))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async rewardEvent(eventId: string) {
    try {
      const response = await this.request().get(Apis.REWARD_EVENT.replace(":eventId", eventId))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async rewardMission(eventId: string, missionId: string) {
    try {
      const response = await this.request().get(Apis.REWARD_MISSION.replace(":eventId", eventId).replace(":missionId", missionId))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async choiceMission(eventId: number, mission_id: number) {
    try {
      const response = await this.request().post(Apis.CHOICE_MISSION, {
        event_id: eventId,
        mission_id: mission_id
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async missionPerforming() {
    try {
      const response = await this.request().get(Apis.PERFORMING_MISSION)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }
}