<template>
  <div class="cl-find-team noselect">
    <v-btn
      class="mr-2"
      icon
      size="24"
      slim
      variant="plain"
      @click="onClickBack"
    >
      <v-icon class="mt-n1" color="rgba(255, 255, 255, 0.2)">
        mdi-arrow-left-bold-circle
      </v-icon>
    </v-btn>
    <span class="text-white cl-font-24-bold">{{ $t("findTeam.title") }}</span>
    <v-divider class="mt-5 mb-5" :thickness="1" color="#613BBB"></v-divider>
    <span class="text-white cl-font-16-normal">
      {{ $t("findTeam.description") }}
    </span>
    <div class="flex mt-7">
      <div class="cl-key-word">
        <span class="text-white cl-font-14-bold ml-2 mr-2">{{
          $t("findTeam.id_team")
        }}</span>
        <v-text-field
          v-model="partyCode"
          max-width="160"
          type="number"
          flat
          dirty
          hide-details
          density="compact"
          variant="solo"
          color="#fff"
          maxlength="10"
          hide-spin-buttons
          bg-color="rgba(36, 22, 69, 1)"
          @keyup.enter="onClickJoinPartyCode"
        >
        </v-text-field>
        <v-btn
          class="ml-1"
          height="39"
          variant="flat"
          color="rgba(99, 194, 129, 1)"
          @click="onClickJoinPartyCode"
        >
          <div class="text-white normal-case cl-font-12-bold">
            {{ $t("findTeam.enter") }}
          </div>
        </v-btn>
      </div>
      <v-btn
        height="45"
        variant="flat"
        color="#FF507A"
        @click.stop="onOpenDialogOption"
      >
        <span class="normal-case cl-font-14-bold">{{
          $t("findTeam.option")
        }}</span>
      </v-btn>
    </div>
    <div v-if="lstParty?.length" class="mt-7 text-white cl-font-18-bold">
      {{ lstParty?.length }} {{ $t("findTeam.team_public") }}
    </div>
    <v-list>
      <div v-for="(item, index) in lstParty" :key="index" class="cl-team mt-4">
        <div class="flex" style="align-items: center; gap: 10px">
          <img
            width="32"
            height="32"
            src="@/assets/images/remove/avatar.svg"
            alt=""
          />
          <div class="cl-font-14-bold">
            <span class="maxline1" style="color: rgba(255, 80, 122, 1)">{{
              item?.party_name
            }}</span>
          </div>
          <div class="cl-rounder cl-font-14-bold">
            <span style="color: rgba(0, 194, 255, 1)">
              {{ item?.party_mode }}
            </span>
          </div>
        </div>
        <div class="flex" style="gap: 30px">
          <div v-for="member in 5" :key="member">
            <div
              v-if="item?.members[member - 1]?.user_uuid"
              class="cl-member-team mt-2"
            >
              <img
                v-if="item?.members[member - 1]?.is_party_leader"
                class="cl-leader-icon"
                src="@/assets/images/battle-lobby/icon_leader.svg"
                alt=""
              />
              <img
                class="rounded-full"
                width="76"
                height="76"
                :src="item?.members[member - 1]?.avatar_url"
                alt=""
              />
              <div class="cl-font-18-bold maxline1 text-white">
                {{ item?.members[member - 1]?.name }}
              </div>
              <div
                v-if="item?.members[member - 1]?.rank"
                class="relative cl-font-16-bold"
              >
                <img
                  class="w-36"
                  :src="item?.members[member - 1]?.rank_background"
                  alt=""
                />
                <div
                  class="w-16 absolute flex justify-center cl-font-24-bold italic right-16 top-2 text-white maxline1"
                >
                  {{ item?.members[member - 1]?.elo }}
                </div>
              </div>
            </div>
            <v-btn
              v-else
              :disable="loadingJoinTeam"
              class="cl-member-team mt-2 justify-center cursor-pointer"
              @click="onClickJoinTeam(item)"
            >
              <img src="@/assets/images/battle-lobby/find_mem.svg" alt="" />
            </v-btn>
          </div>
        </div>
      </div>
    </v-list>
  </div>
  <DialogOption
    :data="dataDialogOption"
    :loading="loading"
    :showDialog="showDialogOption"
    @acceptDialog="onAcceptDialog"
    @closeDialog="onCloseDialogOption"
  />
</template>

<script>
import { ref } from "vue";
import constants from "@/constants";

// component
import DialogOption from "@/components/core/dialog/Option.vue";

// router
import { useRouter } from "vue-router";
import routesPath from "@/modules/battleCS2/routers/routesPath";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";

// mixins
import ranking from "@/mixins/ranking";

// storage
import { getInfoParty, setInfoParty } from "@/helpers/storage";

export default {
  name: "FindTeam",
  mixins: [ranking],
  components: {
    DialogOption,
  },
  setup() {
    const partyCode = ref();
    const lstParty = ref([]);
    const loading = ref(false);
    const loadingJoinTeam = ref(false);
    const dataDialogOption = ref(JSON.parse(getInfoParty()));
    const showDialogOption = ref(false);
    const actionMqtt = [
      {
        action: "remove_party",
        handleAction: (val) => handleActionRemoveParty(val),
      },
      {
        action: "add_party",
        handleAction: (val) => handleActionAddParty(val),
      },
      {
        action: "update_party",
        handleAction: (val) => handleActionUpdateParty(val),
      },
      {
        action: "update_leader",
        handleAction: (val) => handleActionUpdateParty(val),
      },
    ];

    const onOpenDialogOption = () => {
      showDialogOption.value = true;
    };

    const onCloseDialogOption = () => {
      showDialogOption.value = false;
    };

    const router = useRouter();
    const onClickBack = () => {
      router.push(routesPath.ROOM_BATTLE_PATH);
    };

    function handleActionRemoveParty(data) {
      const indexRemove = lstParty.value.findIndex(
        (it) => it.party_id === data.party_id
      );
      if (indexRemove >= 0) {
        lstParty.value.splice(indexRemove, 1);
      }
    }

    function handleActionAddParty(data) {
      lstParty.value.unshift(data);
    }

    function handleActionUpdateParty(data) {
      const indexUpdate = lstParty.value.findIndex(
        (it) => it.party_id === data.party_id
      );
      if (indexUpdate >= 0) {
        lstParty.value[indexUpdate] = data;
      }
    }

    return {
      lstParty,
      actionMqtt,
      partyCode,
      loading,
      loadingJoinTeam,
      showDialogOption,
      dataDialogOption,
      onClickBack,
      onOpenDialogOption,
      onCloseDialogOption,
    };
  },

  beforeUnmount() {
    this.onUnSubscribeMqttPublicParty();
  },

  created() {
    this.getLstParty();
    this.onSubscribeMqttPublicParty();
    this.onListenMessageMqtt();
  },

  methods: {
    async getLstParty() {
      try {
        const res = await BattleService.getLisPublicParty();
        this.lstParty = res.Data;
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },

    async onAcceptDialog(data) {
      try {
        this.loading = true;
        const { Data } = await BattleService.updateParty(data);
        setInfoParty(JSON.stringify(Data));
        this.onCloseDialogOption();
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        this.loading = false;
      }
    },

    async onClickJoinPartyCode() {
      try {
        if (!this.partyCode) {
          return;
        }
        this.partyCode = this.partyCode.trim();
        if (!this.loadingJoinTeam) {
          this.loadingJoinTeam = true;
          await BattleService.joinParty({
            party_code: parseInt(this.partyCode),
          });
          this.$router.push(routesPath.ROOM_BATTLE_PATH);
        }
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        setTimeout(() => {
          this.loadingJoinTeam = false;
        }, 500);
      }
    },

    async onClickJoinTeam(item) {
      try {
        if (!this.loadingJoinTeam) {
          this.loadingJoinTeam = true;
          await BattleService.joinParty({
            party_id: item.party_id,
          });
          this.$router.push(routesPath.ROOM_BATTLE_PATH);
        }
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        setTimeout(() => {
          this.loadingJoinTeam = false;
        }, 500);
      }
    },

    // MQTT
    onUnSubscribeMqttPublicParty() {
      const topicPublicParty = `${process.env.VUE_APP_TOPIC_PUBLIC_PARTY}`;
      this.$clientMqtt.unsubscribe(topicPublicParty);
    },

    // MQTT
    onSubscribeMqttPublicParty() {
      const topicPublicParty = `${process.env.VUE_APP_TOPIC_PUBLIC_PARTY}`;
      this.$clientMqtt.subscribe(topicPublicParty);
    },

    // MQTT
    onListenMessageMqtt() {
      const topicPublicParty = `${process.env.VUE_APP_TOPIC_PUBLIC_PARTY}`;
      this.$clientMqtt.on("message", async (topic, message) => {
        if (topicPublicParty != topic) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        let { Action, Data } = JSON.parse(decoder.decode(message));
        const actionMqtt = this.actionMqtt.find((it) => it.action === Action);
        if (actionMqtt) {
          actionMqtt.handleAction(Data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  margin-top: 0px !important;
  background: transparent !important;
  border: none !important;
  width: max-content;
  padding: 0px !important;
  padding-right: 10px;
  height: calc(100vh - 365px);
  overflow: auto !important;
}
.cl-find-team {
  margin: 20px 30px 20px 30px;
  height: calc(100vh - 110px);
  overflow: hidden;
  .cl-key-word {
    width: 300px;
    height: 45px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.2);
  }
  .cl-team {
    width: 1160px;
    height: 270px;
    padding: 9px 20px 9px 20px;
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.1);
    .cl-member-team {
      width: 200px;
      height: 200px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      border-radius: 8px;
      border: 1px solid rgba(0, 194, 255, 1);
      background: linear-gradient(
        0deg,
        rgba(49, 138, 239, 0.3) 0%,
        rgba(0, 194, 255, 0.3) 55%,
        rgba(49, 138, 239, 0.3) 100%
      );
      .cl-leader-icon {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
  }
  .cl-rounder {
    width: max-content;
    border-radius: 26px;
    padding: 6px 12px 6px 12px;
    background: rgba(36, 22, 69, 1);
  }
}
</style>