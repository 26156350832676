<template>
  <div class="cl-tournament">
    <iframe
      v-bind:src="url"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script lang="ts">
// storage
import { getToken } from "@/helpers/storage";

export default {
  name: "tournamentsModule",
  data() {
    return {
      url: `${process.env.VUE_APP_URL_ESPORT}?user-agent=GPlay-tournaments&token=${getToken()}`,
    };
  },
};
</script>

<style lang="scss" scoped>
.cl-tournament {
  width: 100%;
  height: 100vh;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>