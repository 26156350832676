// helper
import { RESTORE_WINDOW } from "@/helpers/ipcChannels";

export function formattedTimeCountDown(timeLeft: number) {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export function scrollToBottom(elementId: string) {
  setTimeout(() => {
    const ele = document.getElementById(elementId);
    if (ele) {
      ele.scrollTo({ top: ele.scrollHeight, behavior: "smooth" });
    }
  }, 100);
}

export function convertTextTime(time: number) {
  return time > 9 ? time : `0${time}`;
}

export function handleFocusApp() {
  if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
    // eslint-disable-next-line
    const { ipcRenderer } = require("electron");
    ipcRenderer.send(RESTORE_WINDOW);
  }
}


