<template>
  <!-- TEAM INFO -->
  <div class="cl-lobby-title">
    <!-- TEAM A -->
    <div class="cl-title-team">
      <v-list-item>
        <template v-slot:title>
          <div class="cl-name cl-font-24-bold mr-6">
            <span style="color: rgba(255, 80, 122, 1)">Team</span>_{{
              currentMember?.team_index === 1
                ? lobbyInfo?.red_team_name
                : lobbyInfo?.blue_team_name
            }}
          </div>
        </template>
        <template v-slot:append>
          <div class="flex items-center justify-center">
            <Avatar
              :data="{
                avatar_url:
                  currentMember?.team_index === 1
                    ? lobbyInfo?.red_team_logo
                    : lobbyInfo?.blue_team_logo,
                frame_url:
                  currentMember?.team_index === 1
                    ? lobbyInfo?.red_team_frame
                    : lobbyInfo?.blue_team_frame,
              }"
              :tooltip="false"
              :sizeAvatar="64"
              :sizeFrame="70"
            />
          </div>
        </template>
      </v-list-item>
      <!---->
    </div>
    <!-- CENTER -->
    <div class="w-64 cl-title-center cl-font-16-normal relative">
      <div>BO1</div>
      <div class="cl-round">{{ getTextLobbyState() }}</div>
      <div class="text-customPurple cl-font-36-bold absolute top-5 right-8">
        {{
          currentMember?.team_index === 1
            ? currentMatch?.blue_team_score
            : currentMatch?.red_team_score
        }}
      </div>
      <div class="text-customGreen2 cl-font-36-bold absolute top-5 left-8">
        {{
          currentMember?.team_index === 1
            ? currentMatch?.red_team_score
            : currentMatch?.blue_team_score
        }}
      </div>
      <div class="w-full relative h-3 flex justify-center">
        <img
          class="absolute"
          style="top: 1px"
          :class="getPositionPolygon()"
          src="@/assets/images/battle-lobby/polygon_centter.svg"
          alt=""
        />
        <img
          class="absolute top-2"
          src="@/assets/images/bg_center.svg"
          alt=""
        />
        <v-tooltip v-if="lobbyId" width="300" activator="parent" location="top">
          <div>
            <div class="flex justify-between items-center p-2">
              <div class="cl-font-16-bold w-12">
                {{
                  currentMember?.team_index === 1
                    ? lobbyInfo?.red_team_win_rate
                    : lobbyInfo?.blue_team_win_rate ?? 0
                }}%
              </div>
              <div class="cl-font-12-bold">Xác suất thắng</div>
              <div class="cl-font-16-bold w-12">
                {{
                  currentMember?.team_index === 1
                    ? lobbyInfo?.blue_team_win_rate
                    : lobbyInfo?.red_team_win_rate ?? 0
                }}%
              </div>
            </div>
            <v-divider class="border-opacity-50"></v-divider>
            <div class="flex justify-between items-center p-2">
              <div class="cl-font-16-bold w-12">
                {{
                  currentMember?.team_index === 1
                    ? lobbyInfo?.red_team_elo
                    : lobbyInfo?.blue_team_elo ?? 0
                }}
              </div>
              <div class="cl-font-12-bold">Elo trung bình</div>
              <div class="cl-font-16-bold w-12">
                {{
                  currentMember?.team_index === 1
                    ? lobbyInfo?.blue_team_elo
                    : lobbyInfo?.red_team_elo ?? 0
                }}
              </div>
            </div>
          </div>
        </v-tooltip>
      </div>
    </div>
    <!-- TEAM B -->
    <div class="cl-title-team justify-start">
      <v-list-item>
        <template v-slot:title>
          <div class="cl-name cl-font-24-bold ml-6">
            <span style="color: rgba(255, 80, 122, 1)">Team</span>_{{
              currentMember?.team_index === 1
                ? lobbyInfo?.blue_team_name
                : lobbyInfo?.red_team_name
            }}
          </div>
        </template>
        <template v-slot:prepend>
          <div class="flex items-center justify-center">
            <Avatar
              :data="{
                avatar_url:
                  currentMember?.team_index === 1
                    ? lobbyInfo?.blue_team_logo
                    : lobbyInfo?.red_team_logo,
                frame_url:
                  currentMember?.team_index === 1
                    ? lobbyInfo?.blue_team_frame
                    : lobbyInfo?.red_team_frame,
              }"
              :tooltip="false"
              :sizeAvatar="64"
              :sizeFrame="70"
            />
          </div>
        </template>
      </v-list-item>
    </div>
  </div>
  <div v-if="!isEndseries" class="cl-lobby-match">
    <!-- TEAM A -->
    <v-scroll-x-transition>
      <div v-show="showMember" class="cl-team-member" style="align-items: end">
        <div
          class="cl-mem mt-1"
          v-for="(item, index) in styleTeam"
          :key="index"
          :class="item.classTeamA"
        >
          <div
            v-if="
              checkIsParty(
                index,
                currentMember?.team_index === 1
                  ? lobbyInfo?.red_team_member
                  : lobbyInfo?.blue_team_member
              )
            "
            :class="item.classTeamABracket"
          ></div>
          <div class="cl-mem-info" :class="index === 0 ? 'mt-2' : 'mt-0'">
            <img class="absolute top-0 left-0" :src="item.bgImage" alt="" />
            <v-list-item style="padding: 0px 16px !important">
              <template v-slot:prepend>
                <img
                  v-if="index === 0"
                  class="mr-2"
                  src="@/assets/images/battle-lobby/icon_leader.svg"
                  alt=""
                />
                <div class="cl-mem-avatar w-12 h-12">
                  <Avatar
                    :data="
                      currentMember?.team_index === 1
                        ? lobbyInfo?.red_team_member[index]
                        : lobbyInfo?.blue_team_member[index]
                    "
                    :tooltip="false"
                  />
                  <div class="cl-anti-cheat">
                    <img
                      src="@/assets/images/battle-lobby/ic_active_anti_cheat.svg"
                      alt=""
                    />
                  </div>
                </div>
              </template>
              <template v-slot:title>
                <div
                  class="cl-mem-title cl-font-18-bold maxline1"
                  :class="
                    getClassLineThrough(
                      currentMember?.team_index === 1
                        ? lobbyInfo?.red_team_member[index]
                        : lobbyInfo?.blue_team_member[index]
                    )
                  "
                >
                  {{
                    currentMember?.team_index === 1
                      ? lobbyInfo?.red_team_member[index]?.display_name
                      : lobbyInfo?.blue_team_member[index]?.display_name
                  }}
                </div>
              </template>
              <template v-slot:subtitle>
                <div
                  class="cl-mem-subtitle flex flex-nowrap items-center cl-font-14-normal cursor-pointer"
                  :class="
                    getClassLineThrough(
                      currentMember?.team_index === 1
                        ? lobbyInfo?.red_team_member[index]
                        : lobbyInfo?.blue_team_member[index]
                    )
                  "
                  @click="
                    showProfileSteam(
                      currentMember?.team_index === 1
                        ? lobbyInfo?.red_team_member[index]?.steam_meta.steam_id
                        : lobbyInfo?.blue_team_member[index]?.steam_meta
                            .steam_id
                    )
                  "
                >
                  <v-icon size="16">mdi-steam</v-icon>
                  <div class="ml-1 maxline1">
                    {{
                      currentMember?.team_index === 1
                        ? lobbyInfo?.red_team_member[index]?.steam_meta
                            .display_name_steam
                        : lobbyInfo?.blue_team_member[index]?.steam_meta
                            .display_name_steam
                    }}
                  </div>
                </div>
              </template>
              <template v-slot:append>
                <img
                  v-if="
                    currentMember?.team_index === 1
                      ? lobbyInfo?.red_team_member[index]?.rank > 0
                      : lobbyInfo?.blue_team_member[index]?.rank > 0
                  "
                  class="w-16"
                  :src="
                    currentMember?.team_index === 1
                      ? lobbyInfo?.red_team_member[index]?.rank_frame
                      : lobbyInfo?.blue_team_member[index]?.rank_frame
                  "
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/images/battle-lobby/un_rank.svg"
                  alt=""
                  srcset=""
                />
              </template>
            </v-list-item>
          </div>
        </div>
      </div>
    </v-scroll-x-transition>
    <!-- CENTER -->
    <v-expand-transition>
      <div v-show="showMember" class="cl-lobby-center">
        <img
          class="w-32 h-28"
          src="@/assets/images/battle-lobby/vs.svg"
          alt=""
        />
        <div v-if="lobbyInfo?.is_ban_pick_map">
          <div class="text-white cl-font-14-bold">{{ getTexBanPick() }}</div>
          <div class="cl-center-countdown cl-font-36-bold">
            {{ formattedTime }}
          </div>
        </div>
        <div v-else-if="lobbyInfo?.state == lobbyState.running">
          <div
            class="relative w-64 h-14 cursor-pointer flex justify-center items-center border-text-stroke"
            @mouseover="onHover"
            @mouseleave="onUnHover"
            @click="onClickJoinGame"
          >
            <div
              class="absolute top-0 cl-font-30-bold text-white mt-2 uppercase"
            >
              {{ getTexJoinGame() }}
            </div>
            <img :src="btnImage" alt="" />
          </div>
        </div>
        <div v-else>
          <div class="text-white cl-font-14-bold">Đang vào trận</div>
          <div class="cl-center-countdown cl-font-36-bold">
            {{ formattedTime }}
          </div>
        </div>
      </div>
    </v-expand-transition>
    <!-- TEAM B -->
    <v-scroll-x-reverse-transition>
      <div v-show="showMember" class="cl-team-member">
        <div
          v-for="(item, index) in styleTeam"
          :key="index"
          class="cl-mem mt-1"
          :class="item.classTeamB"
        >
          <div
            v-if="
              checkIsParty(
                index,
                currentMember?.team_index === 1
                  ? lobbyInfo?.blue_team_member
                  : lobbyInfo?.red_team_member
              )
            "
            :class="item.classTeamBBracket"
          ></div>
          <div class="cl-mem-info pl-4" :class="index === 0 ? 'mt-2' : 'mt-0'">
            <img
              class="cl-rotateY-180 absolute top-0 left-0"
              :src="item.bgImage"
              alt=""
            />
            <v-list-item style="padding: 0px 16px !important">
              <template v-slot:prepend>
                <img
                  v-if="index === 0"
                  class="mr-2"
                  src="@/assets/images/battle-lobby/icon_leader.svg"
                  alt=""
                />
                <div class="cl-mem-avatar w-12 h-12">
                  <Avatar
                    :data="
                      currentMember?.team_index === 1
                        ? lobbyInfo?.blue_team_member[index]
                        : lobbyInfo?.red_team_member[index]
                    "
                    :tooltip="false"
                  />
                  <div class="cl-anti-cheat">
                    <img
                      src="@/assets/images/battle-lobby/ic_active_anti_cheat.svg"
                      alt=""
                    />
                  </div>
                </div>
              </template>
              <template v-slot:title>
                <div
                  class="cl-mem-title cl-font-18-bold maxline1"
                  :class="
                    getClassLineThrough(
                      currentMember?.team_index === 1
                        ? lobbyInfo?.blue_team_member[index]
                        : lobbyInfo?.red_team_member[index]
                    )
                  "
                >
                  {{
                    currentMember?.team_index === 1
                      ? lobbyInfo?.blue_team_member[index]?.display_name
                      : lobbyInfo?.red_team_member[index]?.display_name
                  }}
                </div>
              </template>
              <template v-slot:subtitle>
                <div
                  class="cl-mem-subtitle flex flex-nowrap items-center cl-font-14-normal cursor-pointer"
                  :class="
                    getClassLineThrough(
                      currentMember?.team_index === 1
                        ? lobbyInfo?.blue_team_member[index]
                        : lobbyInfo?.red_team_member[index]
                    )
                  "
                  @click="
                    showProfileSteam(
                      currentMember?.team_index === 1
                        ? lobbyInfo?.blue_team_member[index]?.steam_meta
                            .steam_id
                        : lobbyInfo?.red_team_member[index]?.steam_meta.steam_id
                    )
                  "
                >
                  <v-icon size="16">mdi-steam</v-icon>
                  <div class="ml-1 maxline1">
                    {{
                      currentMember?.team_index === 1
                        ? lobbyInfo?.blue_team_member[index]?.steam_meta
                            .display_name_steam
                        : lobbyInfo?.red_team_member[index]?.steam_meta
                            .display_name_steam
                    }}
                  </div>
                </div>
              </template>
              <template v-slot:append>
                <img
                  v-if="
                    currentMember?.team_index === 1
                      ? lobbyInfo?.blue_team_member[index]?.rank > 0
                      : lobbyInfo?.red_team_member[index]?.rank > 0
                  "
                  class="w-16"
                  :src="
                    currentMember?.team_index === 1
                      ? lobbyInfo?.blue_team_member[index]?.rank_frame
                      : lobbyInfo?.red_team_member[index]?.rank_frame
                  "
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/images/battle-lobby/un_rank.svg"
                  alt=""
                  srcset=""
                />
              </template>
            </v-list-item>
          </div>
        </div>
      </div>
    </v-scroll-x-reverse-transition>
  </div>
  <!-- RESULT -->
  <div v-else class="cl-lobby-result">
    <v-data-table-virtual
      :headers="headers"
      :items="resultsTeamA"
      :loading="loadingResult"
      hide-no-data
    >
      <template v-slot:loading>
        <v-skeleton-loader
          style="z-index: -1"
          type="table-row@5"
          color="transparent"
        ></v-skeleton-loader>
      </template>
      <template v-slot:[`header.elo`]="{ column }">
        <span
          :class="
            parseInt(resultsTeamA[0]?.elo) >= 0
              ? 'text-customYellow2'
              : 'text-customPink'
          "
        >
          {{ column.title }}
        </span>
      </template>
      <template v-slot:[`header.damage`]="{ column }">
        <span>
          {{ column.title }}
        </span>
      </template>
      <template v-slot:[`header.mvp`]="{ column }">
        <span>
          {{ column.title }}
        </span>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="cl-col">
            <v-list-item>
              <template v-slot:title>
                <div
                  class="cl-mem-title cl-font-18-bold maxline1"
                  :class="getClassLineThrough(item)"
                >
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:subtitle>
                <div
                  class="cl-mem-subtitle flex items-center cl-font-14-normal cursor-pointer"
                  :class="getClassLineThrough(item)"
                  @click="showProfileSteam(item?.steam_id)"
                >
                  <v-icon size="16">mdi-steam</v-icon>
                  <div class="maxline1">&nbsp;{{ item.steam_name }}</div>
                </div>
              </template>
              <template v-slot:prepend>
                <div
                  class="flex justify-center items-center w-12 h-12 mr-3"
                  :class="
                    item?.frame_url
                      ? ''
                      : 'rounded-full border-2 border-customPurple'
                  "
                >
                  <Avatar :data="item" />
                </div>
              </template>
            </v-list-item>
          </td>
          <td class="cl-col">
            {{ item.kill }}
          </td>
          <td class="cl-col">
            {{ item.die }}
          </td>
          <td class="cl-col">
            {{ item.assit }}
          </td>
          <td class="cl-col">
            {{ calculatePercentage(item.headshot, item.kill) }}
          </td>
          <td class="cl-col">
            {{ item?.headshot ?? 0 }}
          </td>
          <td class="cl-col">
            {{ item?.mvp ?? 0 }}
          </td>
          <td class="cl-col">
            {{ item?.damage ?? 0 }}
          </td>
          <td class="cl-col">
            <span
              :class="item?.elo >= 0 ? 'text-customYellow2' : 'text-customPink'"
            >
              {{ item?.elo }}
            </span>
          </td>
          <td class="cl-col">
            <div class="cl-action">
              <!-- <v-btn
                v-if="isShowAction(item)"
                class="cl-btn-add-friend cl-font-12-bold"
                @click.stop="onAddFriend(item)"
              >
                Kết bạn
              </v-btn> -->
              <v-btn
                v-if="isShowAction(item)"
                width="32px"
                height="32px"
                class="cl-btn-report"
                @click.stop="onOpenDialogReport(item)"
              >
                <v-icon>mdi-shield-alert-outline</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table-virtual>
    <div class="cl-result-center">
      <img src="@/assets/images/battle-lobby/ic_result_center.svg" alt="" />
    </div>
    <v-data-table-virtual
      :headers="headers"
      :items="resultsTeamB"
      :loading="loadingResult"
      hide-no-data
    >
      <template v-slot:loading>
        <v-skeleton-loader
          style="z-index: -1"
          type="table-row@5"
          color="transparent"
        ></v-skeleton-loader>
      </template>
      <template v-slot:[`header.elo`]="{ column }">
        <span
          :class="
            parseInt(resultsTeamB[0]?.elo) >= 0
              ? 'text-customYellow2'
              : 'text-customPink'
          "
        >
          {{ column.title }}
        </span>
      </template>
      <template v-slot:[`header.damage`]="{ column }">
        <span>
          {{ column.title }}
        </span>
      </template>
      <template v-slot:[`header.mvp`]="{ column }">
        <span>
          {{ column.title }}
        </span>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="cl-col">
            <v-list-item>
              <template v-slot:title>
                <div
                  class="cl-mem-title cl-font-18-bold maxline1"
                  :class="getClassLineThrough(item)"
                >
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:subtitle>
                <div
                  class="cl-mem-subtitle flex items-center cl-font-14-normal cursor-pointer"
                  :class="getClassLineThrough(item)"
                  @click="showProfileSteam(item?.steam_id)"
                >
                  <v-icon size="16">mdi-steam</v-icon>
                  <div class="maxline1">&nbsp;{{ item.steam_name }}</div>
                </div>
              </template>
              <template v-slot:prepend>
                <div
                  class="flex justify-center items-center w-12 h-12 mr-3"
                  :class="
                    item?.frame_url
                      ? ''
                      : 'rounded-full border-2 border-customPurple'
                  "
                >
                  <Avatar :data="item" />
                </div>
              </template>
            </v-list-item>
          </td>
          <td class="cl-col">
            {{ item.kill }}
          </td>
          <td class="cl-col">
            {{ item.die }}
          </td>
          <td class="cl-col">
            {{ item.assit }}
          </td>
          <td class="cl-col">
            {{ calculatePercentage(item.headshot, item.kill) }}
          </td>
          <td class="cl-col">
            {{ item?.headshot ?? 0 }}
          </td>
          <td class="cl-col">
            {{ item?.mvp ?? 0 }}
          </td>
          <td class="cl-col">
            {{ item?.damage ?? 0 }}
          </td>
          <td class="cl-col">
            <span
              :class="item?.elo >= 0 ? 'text-customYellow2' : 'text-customPink'"
            >
              {{ item?.elo }}
            </span>
          </td>
          <td class="cl-col">
            <div class="cl-action">
              <!-- <v-btn
                v-if="isShowAction(item)"
                class="cl-btn-add-friend cl-font-12-bold"
                @click.stop="onAddFriend(item)"
              >
                Kết bạn
              </v-btn> -->
              <v-btn
                v-if="isShowAction(item)"
                width="32px"
                height="32px"
                class="cl-btn-report"
                @click.stop="onOpenDialogReport(item)"
              >
                <v-icon>mdi-shield-alert-outline</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table-virtual>
  </div>
  <DialogReport
    ref="dialogReport"
    v-model="showDialogReport"
    :player="playerReport"
    :onCloseDialog="() => (showDialogReport = false)"
    :onAccept="onAcceptReport"
  />
</template>

<script>
import constants from "@/constants";
import { computed, ref, onMounted, watch } from "vue";
import { formattedTimeCountDown } from "@/helpers/utils";

// component
import DialogReport from "@/components/core/dialog/Report.vue";
import Avatar from "@/components/core/Avatar.vue";
import btnPlay from "@/assets/images/btn/btn_play.svg";
import btnPlayHover from "@/assets/images/btn/btn_play_hover.svg";

// mixins
import steamConnect from "@/mixins/steamConnect";

// service
import { AuthService } from "@/services/authService";
import { BattleService } from "@/modules/battleCS2/services/battleServices";

// sotorage
import { getUserInfo } from "@/helpers/storage";

export default {
  mixins: [steamConnect],
  props: {
    lobbyId: {
      typeof: Number,
      default: null,
    },
    isEndseries: {
      typeof: Boolean,
      default: false,
    },
    lobbyInfo: {
      typeof: Object,
      default: null,
    },
    currentMatch: {
      typeof: Object,
      default: null,
    },
    currentMember: {
      typeof: Object,
      default: null,
    },
  },

  components: {
    DialogReport,
    Avatar,
  },

  setup(props) {
    const gameCode = ref("cs2");
    const resultsTeamA = ref([]);
    const resultsTeamB = ref([]);
    const loadingResult = ref(false);

    const lobbyState = ref(constants.LOBBY_STATE);
    const playerState = ref(constants.PLAYER_STATE);

    const timeLeft = ref(0);

    const intervalId = ref(null);

    const showMember = ref(false);

    const showDialogReport = ref(false);

    const playerReport = ref(null);

    const btnImage = ref(btnPlay);

    const headers = ref([
      {
        title: "",
        align: "start",
        key: "name",
        sortable: false,
      },
      {
        title: "K",
        align: "start",
        key: "kill",
        sortable: false,
      },
      { title: "D", align: "start", key: "die", sortable: false },
      { title: "A", align: "start", key: "assit", sortable: false },
      { title: "HS%", align: "start", key: "headshot", sortable: false },
      { title: "HS", align: "start", key: "headshot", sortable: false },
      { title: "MVP", align: "start", key: "mvp", sortable: false },
      { title: "DMG", align: "start", key: "damage", sortable: false },
      { title: "ELO", align: "start", key: "elo", sortable: false },
      { title: "", align: "start", key: "", sortable: false },
    ]);

    const styleTeam = ref([
      {
        classTeamA: "cl-mem-right-65",
        classTeamABracket: "cl-teamA-bracket",
        classTeamB: "cl-mem-left-65",
        classTeamBBracket: "cl-teamB-bracket",
        bgImage: require("@/assets/images/battle-lobby/mem_leader.svg"),
      },
      {
        classTeamABracket: "cl-teamA-slot2-bracket",
        classTeamBBracket: "cl-teamB-slot2-bracket",
        bgImage: require("@/assets/images/battle-lobby/mem_1.svg"),
      },
      {
        classTeamABracket: "cl-teamA-slot3-bracket",
        classTeamBBracket: "cl-teamB-slot3-bracket",
        bgImage: require("@/assets/images/battle-lobby/mem_2.svg"),
      },
      {
        classTeamA: "cl-mem-right-65",
        classTeamB: "cl-mem-left-65",
        classTeamABracket: "cl-teamA-slot4-bracket",
        classTeamBBracket: "cl-teamB-slot4-bracket",
        bgImage: require("@/assets/images/battle-lobby/mem_3.svg"),
      },
      {
        classTeamA: "cl-mem-right-130",
        classTeamB: "cl-mem-left-130",
        bgImage: require("@/assets/images/battle-lobby/mem_4.svg"),
      },
    ]);

    const formattedTime = computed(() => {
      return formattedTimeCountDown(JSON.parse(JSON.stringify(timeLeft.value)));
    });

    const onCountDown = (val) => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
      timeLeft.value = val;
      intervalId.value = setInterval(() => {
        if (timeLeft.value > 0) {
          timeLeft.value -= 1;
          return;
        }
        clearInterval(intervalId.value);
      }, 1000);
    };

    watch(
      () => props.lobbyInfo?.ban_pick_map_team_idx,
      () => {
        if (props.lobbyInfo.is_ban_pick_map) {
          const time =
            props.lobbyInfo.ban_pick_map_start_time > 20
              ? 20
              : props.lobbyInfo.ban_pick_map_start_time;
          onCountDown(time);
        }
      }
    );

    watch(
      () => props.lobbyInfo?.is_ban_pick_map,
      () => {
        if (
          !props.lobbyInfo?.is_ban_pick_map &&
          props.lobbyInfo?.state !== lobbyState.value.running
        ) {
          onCountDown(20);
        }
      }
    );

    watch(
      () => props.lobbyInfo?.state,
      () => {
        if (props.lobbyInfo?.state === lobbyState.value.running) {
          if (intervalId.value) {
            clearInterval(intervalId.value);
          }
        }
      }
    );

    function onHover() {
      btnImage.value = btnPlayHover;
    }

    function onUnHover() {
      btnImage.value = btnPlay;
    }

    function calculatePercentage(part, total) {
      if (!part || parseInt(part) == 0 || !total || parseInt(total) == 0) {
        return "0%";
      }
      let percentage = (part / total) * 100;
      return `${parseInt(percentage)}%`;
    }

    function setResultTeamA(data) {
      resultsTeamA.value = data;
    }

    function setResultTeamB(data) {
      resultsTeamB.value = data;
    }

    function setLoadingResult(data) {
      loadingResult.value = data;
    }

    onMounted(() => {
      setTimeout(() => {
        showMember.value = true;
      }, 300);
    });

    return {
      headers,
      gameCode,
      btnImage,
      styleTeam,
      showMember,
      playerReport,
      showDialogReport,
      formattedTime,
      lobbyState,
      playerState,
      resultsTeamA,
      resultsTeamB,
      loadingResult,
      onHover,
      onUnHover,
      setResultTeamA,
      setResultTeamB,
      setLoadingResult,
      calculatePercentage,
    };
  },

  methods: {
    getClassLineThrough(member) {
      return member?.banned ? "line-through" : "";
    },

    isShowAction(item) {
      const userInfo = JSON.parse(getUserInfo());
      return Boolean(this.lobbyId && userInfo?.user_id !== item.userId);
    },

    getPositionPolygon() {
      if (
        this.lobbyInfo?.red_team_win_rate === this.lobbyInfo?.blue_team_win_rate
      ) {
        return "";
      }
      if (this.currentMember?.team_index === 1) {
        if (
          this.lobbyInfo?.red_team_win_rate > this.lobbyInfo?.blue_team_win_rate
        ) {
          return "mr-5";
        }
        return "ml-5";
      }
      if (
        this.lobbyInfo?.red_team_win_rate > this.lobbyInfo?.blue_team_win_rate
      ) {
        return "ml-5";
      }
      return "mr-5";
    },

    getTexBanPick() {
      if (!this.lobbyInfo?.is_ban_pick_map) {
        return;
      }
      if (
        this.currentMember?.team_index === this.lobbyInfo?.ban_pick_map_team_idx
      ) {
        return this.currentMember?.is_leader
          ? "Bạn đang cấm bản đồ"
          : "Đội trưởng đang cấm bản đồ";
      }
      return "Đối thủ đang cấm bản đồ";
    },

    getTexJoinGame() {
      if (this.lobbyInfo?.is_ban_pick_map) {
        return;
      }
      switch (this.currentMember?.state) {
        case constants.PLAYER_STATE.connectedGame:
          return "Trong trận";
        default:
          return "Vào trận";
      }
    },

    getTextLobbyState() {
      if (this.isEndseries) {
        return "Kết quả";
      }
      if (this.lobbyInfo?.is_ban_pick_map) {
        return "Chọn Map";
      }
      switch (this.lobbyInfo?.state) {
        case constants.LOBBY_STATE.init:
          return "Chọn Map";
        case constants.LOBBY_STATE.creatingServer:
          return "Tạo Trận";
        case constants.LOBBY_STATE.pause:
          return "Tạm Dừng";
        case constants.LOBBY_STATE.running:
          return "Thi Đấu";
      }
    },

    onClickJoinGame() {
      if (this.currentMember?.state === constants.PLAYER_STATE.connectedGame)
        return;
      this.$emit("joinGame");
    },

    async onAddFriend(item) {
      try {
        await AuthService.addFriend({
          FriendId: item.userId,
        });
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t("drawer_friends.add_friend_success", {
            name: item?.display_name,
          }),
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },

    onOpenDialogReport(item) {
      this.playerReport = item;
      this.showDialogReport = true;
    },

    async onAcceptReport() {
      try {
        const payload = {
          game_name: this.gameCode,
          game_id: this.lobbyInfo?.ranking_id,
          accused_uuid: this.playerReport?.userId,
          reason: this.$refs?.dialogReport.getResonReport(),
          description: this.$refs?.dialogReport.getResonReport(),
        };
        await BattleService.reportUser(payload);
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: "Đã gửi báo cáo",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        this.showDialogReport = false;
      }
    },

    checkIsParty(index, arrayTeam) {
      if (arrayTeam && index + 1 < arrayTeam.length) {
        return arrayTeam[index].party_id === arrayTeam[index + 1].party_id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-overlay__content) {
  background: #502f9f !important;
}
::v-deep(table) {
  border-collapse: separate !important;
  border-spacing: 0px !important;
}
::v-deep(.v-table) {
  background: transparent !important;
}
::v-deep(.v-data-table__th) {
  height: 40px !important;
  border: none !important;
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700 !important;
}

::v-deep(.v-data-table-progress__loader) {
  display: none !important;
}

.v-list-item {
  padding: 0px !important;
}

.cl-lobby-title {
  height: 75px;
  margin: 15px 20px 0px 10px;
  display: flex;
  justify-content: space-between;
  .cl-title-center {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    color: rgba(255, 80, 122, 1);
    .cl-round {
      font-weight: 700;
      color: rgba(255, 255, 255, 1);
    }
  }
  .cl-title-team {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    img {
      width: 64px;
    }
    .cl-name {
      color: #ffffff;
      white-space: nowrap;
    }
  }
}
.cl-lobby-match {
  flex: 1;
  display: flex;
  gap: 100px;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 15px 0px;
  background: linear-gradient(
    90deg,
    rgba(123, 114, 198, 0) 0%,
    rgba(123, 114, 198, 0.51) 50%,
    rgba(123, 114, 198, 0) 100%
  );
  border-top: 1px solid rgba(97, 59, 187, 1);
  .cl-team-member {
    display: flex;
    position: relative;
    flex-direction: column;
    .cl-bg-teamA {
      top: 25px;
      left: -9px;
      position: absolute;
    }
    .cl-mem {
      width: 364px;
      position: relative;
      .cl-mem-info {
        width: 364px;
        height: 60px;
        align-content: center;
        padding: 0px 30px 0px 30px;
        .cl-mem-avatar {
          position: relative;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          img {
            object-fit: cover;
          }
          .cl-anti-cheat {
            width: 12px;
            height: 16px;
            top: 26px;
            right: -3px;
            position: absolute;
            img {
              width: 12px;
              height: 16px;
            }
          }
        }
        .cl-mem-title {
          color: rgba(255, 255, 255, 1);
        }
        .cl-mem-subtitle {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .cl-mem-left-65 {
      margin-left: 65px;
    }
    .cl-mem-left-130 {
      margin-left: 130px;
    }
    .cl-mem-right-65 {
      margin-right: 65px;
    }
    .cl-mem-right-130 {
      margin-right: 130px;
    }
  }
  .cl-lobby-center {
    height: 335px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    align-items: center;
    .cl-center-countdown {
      text-align: center;
      color: rgba(123, 114, 198, 1);
      // animation: beat 1s linear infinite;
    }
    .cl-btn-play-now {
      display: flex;
      cursor: pointer;
      position: relative;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      &:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }
  .cl-rotateY-180 {
    transform: rotateY(180deg);
  }
  .cl-rotateX-180 {
    transform: rotateX(180deg);
  }
}
.cl-lobby-result {
  flex: 1;
  gap: 10px;
  display: flex;
  justify-content: space-around;

  padding: 0px 20px 0px 20px;
  border-top: 1px solid rgba(97, 59, 187, 1);
  .cl-result-center {
    padding: 0px;
    width: max-content;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cl-col {
    height: 60px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 0.05);
  }

  .cl-col:first-child {
    padding: 0px 5px !important;
  }

  .cl-action {
    gap: 8px;
    top: 10px;
    right: 10px;
    position: absolute;
    width: max-content;
    .cl-btn-add-friend {
      width: 99px;
      height: 32px;
      border-radius: 3px;
      text-transform: none;
      background: #63c281;
      color: rgba(255, 255, 255, 1);
    }
    .cl-btn-report {
      min-width: 32px;
      border-radius: 3px;
      color: rgba(255, 255, 255, 1);
      background: #ff507a;
    }
  }
  tr {
    position: relative;
    background: rgba(255, 255, 255, 0.05);
    &:nth-child(odd) {
      background: transparent;
    }
  }
}
.border-text-stroke {
  -webkit-text-stroke: 3px #e4a42c;
  paint-order: stroke fill;
}
.border-text-stroke:hover {
  -webkit-text-stroke: 3px #df561a;
  paint-order: stroke fill;
  img {
    object-fit: cover;
  }
}
</style>