<template>
  <div class="cl-ban pt-1 cl-font-14-normal">
    <v-icon size="13" color="#D81F26">mdi-block-helper</v-icon>&nbsp;
    <span>{{ formattedTime }}</span>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { formattedTimeCountDown } from "@/helpers/utils";

export default {
  emit: ["closeBanTime"],

  props: {
    timeCountDown: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const intervalId = ref(null);
    const banTime = ref(props.timeCountDown);

    const formattedTime = computed(() => {
      return formattedTimeCountDown(JSON.parse(JSON.stringify(banTime.value)));
    });

    watch(
      () => props.timeCountDown,
      () => {
        onCountDown();
      }
    );

    const onCountDown = () => {
      if (banTime.value > 0) {
        if (intervalId.value) {
          clearInterval(intervalId.value);
        }
        banTime.value = props.timeCountDown;
        intervalId.value = setInterval(() => {
          if (banTime.value > 0) {
            banTime.value -= 1;
            return;
          }
          clearInterval(intervalId.value);
          emit('closeBanTime')
        }, 1000);
      }
    };

    onMounted(() => {
      if (props.timeCountDown > 0) {
        onCountDown();
      }
    });

    return {
      banTime,
      formattedTime,
    };
  },
};
</script>

<style scoped>
.cl-ban {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  top: 190px;
  width: 60px;
  height: 20px;
  border-radius: 10px;
  color: #ff6b6a;
  border: 1px solid #ff6b6a;
  background: #00000099;
}
</style>