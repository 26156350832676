<template>
  <!-- MAP INFO TODO: MATCHING -->
  <div v-if="!isEndseries" class="cl-map-info">
    <div
      class="cl-map cl-font-14-bold"
      v-for="(item, index) in lobbyInfo?.map_list"
      :key="index"
    >
      <div :class="isBan(item) ? 'cl-map-image-ban' : 'cl-map-image'">
        <img style="width: 150px; height: 90px" :src="item?.map_image" />
        <div v-show="isBan(item)" class="cl-map-ban cl-font-24-bold">Cấm</div>
        <div
          v-show="
            !isBan(item) &&
            lobbyInfo?.is_ban_pick_map &&
            currentMember?.is_leader &&
            currentMember?.team_index == lobbyInfo?.ban_pick_map_team_idx
          "
          class="cl-map-ban-hover cl-font-24-bold"
        >
          <v-btn
            class="cl-btn-ban cl-font-24-bold"
            @click.stop="onBanMap(item, index)"
          >
            Cấm
          </v-btn>
        </div>
      </div>
      <div class="capitalize">{{ item?.map_name }}</div>
    </div>
  </div>
  <!-- RESULT TODO: RESULT-->
  <div v-else class="cl-map-info cl-map-result">
    <div class="cl-map cl-font-14-bold">
      <div class="cl-map-image-ban">
        <img
          style="width: 150px; height: 90px"
          :src="currentMatch?.map_image"
        />
      </div>
      <div class="capitalize">{{ currentMatch?.map_name }}</div>
    </div>
  </div>
</template>

<script>
// constants
import constants from "@/constants";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";

export default {
  props: {
    isEndseries: {
      typeof: Boolean,
      default: false,
    },
    currentMatch: {
      typeof: Object,
      default: null,
    },
    currentMember: {
      typeof: Object,
      default: null,
    },
    lobbyInfo: {
      typeof: Object,
      default: null,
    },
  },

  methods: {
    isBan(map) {
      return Boolean(map.state === constants.MAP_STATE.ban);
    },

    async onBanMap(item) {
      try {
        const payload = {
          id: item.id,
          state: constants.MAP_STATE.ban,
        };
        await BattleService.banPickMap(
          payload,
          this.lobbyInfo.game_code,
          this.lobbyInfo.lobby_id
        );
        item.state = constants.MAP_STATE.ban;
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-map-info {
  height: 140px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  margin: 10px 70px 30px 70px;
  .cl-map {
    width: 160px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    .cl-map-image-ban,
    .cl-map-image {
      width: 150px;
      height: 90px;
      position: relative;
      margin-bottom: 2px;
      object-fit: cover;
      .cl-map-ban-hover,
      .cl-map-ban {
        top: 0px;
        text-align: center;
        align-content: center;
        width: 150px;
        height: 90px;
        position: absolute;
        color: #e44e4e;
        -webkit-text-stroke: 1px #000000;
        paint-order: stroke fill;
      }
      .cl-map-ban-hover {
        display: none;
        background: rgba(0, 0, 0, 0.7);
        .cl-btn-ban {
          width: 95px;
          height: 37px;
          color: rgba(123, 114, 198, 1);
          background: rgba(36, 22, 69, 1);
        }
      }
      &:hover {
        .cl-map-ban-hover {
          display: block;
        }
      }
    }
    .cl-map-image-ban {
      img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }
  }
}
</style>