<template>
  <div class="cl-items-player">
    <div v-for="supCategory in lstSubCategory" :key="supCategory.title">
      <div
        class="h-9 bg-customBlack2 text-white cl-font-14-bold flex items-center pl-4 rounded-lg"
      >
        {{ supCategory.title }}
      </div>
      <div class="flex flex-wrap gap-5 py-5">
        <div
          v-for="(item, index) in supCategory.lstItem"
          :key="index"
          style="width: 180px"
          class="cl-item h-56 cursor-pointer flex flex-col items-center bg-customPurple4 py-4 rounded-xxl"
          :class="
            item.UserItemStatus === userItemStatus.using
              ? 'border-2 border-customGreen3'
              : ''
          "
          @dblclick="changeUsingItem(item, supCategory)"
        >
          <v-avatar :size="150" :image="item.ImageUrl"></v-avatar>
          <v-icon
            v-if="item.UserItemStatus === userItemStatus.using"
            class="mt-3"
            :size="30"
            color="rgba(99, 194, 129, 1)"
          >
            mdi-check-circle
          </v-icon>
          <!-- <v-btn
            v-else
            class="mt-3 cl-btn-using"
            base-color="rgba(99, 194, 129, 1)"
            :disabled="item.loading"
            @click.stop="changeUsingItem(item, supCategory)"
          >
            <span class="text-white normal-case cl-font-14-bold">Su</span>
          </v-btn> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import constants from "@/constants";

// services
import { ProfileService } from "@/modules/profile/services/profileService";

// storage
import { setUserInfo, getUserInfo } from "@/helpers/storage";

export default {
  name: "ItemsPlayerVue",

  props: {
    data: {
      typeof: Boolean,
      default: [],
    },
  },

  setup(props) {
    const userItemStatus = ref(constants.USER_ITEM_STATUS);
    const lstSubCategory = ref([
      {
        title: "Avatar",
        key: "avatar_url",
        lstItem: [],
        changeItem: (data) => onChangeAvatar(data),
        subCategory: [
          constants.SUB_CATEGORY.avatar,
          constants.SUB_CATEGORY.avatarExclusive,
        ],
      },
      {
        title: "Khung VIP",
        key: "frame_url",
        lstItem: [],
        changeItem: (data) => onChangeFrame(data),
        subCategory: [
          constants.SUB_CATEGORY.frame,
          constants.SUB_CATEGORY.frameExclusive,
        ],
      },
      {
        title: "Hiệu ứng",
        key: "effect_url",
        lstItem: [],
        changeItem: (data) => onChangeEffect(data),
        subCategory: [
          constants.SUB_CATEGORY.effect,
          constants.SUB_CATEGORY.effectExclusive,
        ],
      },
    ]);

    watch(
      () => props.data,
      () => {
        lstSubCategory.value.forEach((item) => {
          item.lstItem = [];
          item.loading = false;
          item.subCategory.forEach((sub) => {
            item.lstItem = item.lstItem.concat(
              props.data.filter(
                (val) =>
                  val.ItemSubcategoryId === sub &&
                  (val.UserItemStatus === constants.USER_ITEM_STATUS.owned ||
                    val.UserItemStatus === constants.USER_ITEM_STATUS.using)
              )
            );
          });
        });
      }
    );

    async function onChangeAvatar(data) {
      await ProfileService.changeAvatar(data?.ItemId);
    }

    async function onChangeFrame(data) {
      await ProfileService.changeFrame(data?.ItemId);
    }

    async function onChangeEffect(data) {
      await ProfileService.changeEffect(data?.ItemId);
    }

    return {
      userItemStatus,
      lstSubCategory,
    };
  },

  methods: {
    async changeUsingItem(item, supCategory) {
      try {
        item.loading = true;
        await supCategory.changeItem(item);

        // set item active
        this.handleItemUsing(item, supCategory);

        // notification success
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: "Sử dụng vật phẩm thành công",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        item.loading = false;
        this.updateUserInfo(item, supCategory);
      }
    },

    handleItemUsing(item, supCategory) {
      let currentActive = supCategory.lstItem.find(
        (it) => it.UserItemStatus === constants.USER_ITEM_STATUS.using
      );
      if (currentActive) {
        currentActive.UserItemStatus = constants.USER_ITEM_STATUS.owned;
      }
      item.UserItemStatus = constants.USER_ITEM_STATUS.using;
    },

    updateUserInfo(item, supCategory) {
      const userInfo = JSON.parse(getUserInfo());
      userInfo[supCategory.key] = item.ImageUrl;
      setUserInfo(JSON.stringify(userInfo));
      this.$store.dispatch("setUpdateInfoPlayer", true);
      setTimeout(() => {
        this.$store.dispatch("setUpdateInfoPlayer", false);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-items-player {
  min-width: 980px;
  .cl-item {
    &:hover {
      .cl-btn-using {
        display: block;
      }
    }
    .cl-btn-using {
      display: none;
    }
  }
}
</style>