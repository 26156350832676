<template>
  <v-card class="z">
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        class="cl-drawer"
        location="right"
        v-bind:width="300"
      >
        <div class="cl-header cl-font-16-bold" @click.stop="onCloseDrawer">
          <div>
            <v-icon size="12">mdi-arrow-expand-right</v-icon>
          </div>
          <div class="noselect" style="margin-left: 60px">
            {{ $t("drawer_chat.team_chat") }}
          </div>
        </div>
        <v-list id="id-list-chat" class="cl-list-chat" @scroll="handleScroll">
          <v-list-item v-for="(item, index) in items" class="mb-2" :key="index">
            <div v-if="isCurrentUser(item.UserId)" class="cl-current-user">
              <div class="cl-content-chat-mem cl-font-14-bold cl-content-user">
                <span class="text-customYellow3"> You </span>:
                <span
                  class="cl-font-14-normal leading-5"
                  :style="{ color: item.ChatTextColor }"
                >
                  {{ item.Content }}
                </span>
              </div>
            </div>
            <div v-else class="cl-content-chat-mem cl-font-14-bold">
              <span :style="{ color: item.ChatTextColor }">
                {{ item.UserDisplayName }}
              </span>
              :
              <span class="cl-font-14-normal leading-5">{{
                item.Content
              }}</span>
            </div>
          </v-list-item>
        </v-list>
        <div class="cl-action-bottom">
          <v-text-field
            ref="textChat"
            v-model="text"
            class="ma-2 no-border"
            color="transparent"
            variant="outlined"
            hide-details
            bg-color="rgba(255, 255, 255, 0.1)"
            density="compact"
            maxlength="150"
            :placeholder="$t('drawer_chat.placeholder_chat')"
            @keyup.enter="onSendMessage()"
          >
          </v-text-field>
          <BaseEmojiPicker class="ma-2 ml-0" @addEmoji="onAddEmoji" />
          <div class="cl-btn-send" @click="onSendMessage()">
            <v-icon class="mt-n1 mr-n1" size="16" color="#ffffff">
              mdi-send mdi-rotate-315
            </v-icon>
          </div>
        </div>
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

// ultil
import { aesEncrypt } from "@/helpers/aes256";
import { scrollToBottom } from "@/helpers/utils";

// service
import { ChatService } from "@/services/chatService";

// sotorage
import { getUserInfo } from "@/helpers/storage";

export default {
  props: {
    id: {
      typeof: String,
      default: null,
    },
  },

  setup(props) {
    const isLoadMore = ref(true);
    const items = ref([]);
    const text = ref(null);
    const route = useRoute();
    const store = useStore(key);
    const drawer = computed(() => store.state.drawerChat);
    const onCloseDrawer = () => {
      store.dispatch("setDrawerChat", false);
    };

    watch(
      () => route.path,
      () => {
        onCloseDrawer();
      }
    );

    async function onSendMessage() {
      try {
        if (!text.value || !text.value.length) return;
        await ChatService.sendMessageIngame({ message: this.getPayloadChat() });
        this.text = null;
        scrollToBottom("id-list-chat");
      } catch (error) {
        console.log(error);
      }
    }

    function getPayloadChat() {
      const content = text.value;
      const payload = aesEncrypt(
        JSON.stringify({
          TargetId: props.id,
          TargetType: "inGameChats",
          Content: content,
          IsSystem: 0,
          ChatTextColor: "#ffffff",
          AccountType: 3,
        })
      );
      return payload;
    }

    return {
      items,
      text,
      drawer,
      isLoadMore,
      onCloseDrawer,
      getPayloadChat,
      onSendMessage,
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.onSubscribeMqttChat();
        this.onListenMessageMqtt();
        this.getMessage();
      }
    },
  },

  beforeUnmount() {
    this.onUnSubscribeMqttChat();
  },

  methods: {
    isCurrentUser(userId) {
      const userInfo = JSON.parse(getUserInfo());
      return Boolean(userInfo.user_id === userId);
    },

    onAddEmoji(emoji) {
      const position = this.$refs.textChat;
      var startPos = position.selectionStart,
        endPos = position.selectionEnd,
        tmpStr = position.value;
      this.text =
        tmpStr.substring(0, startPos) +
        emoji +
        tmpStr.substring(endPos, tmpStr.length);
      setTimeout(() => {
        startPos += emoji.length;
        position.focus();
        position.setSelectionRange(startPos, startPos);
      }, 10);
    },

    async getMessage(createdAt) {
      try {
        this.isLoadMore = false;
        const res = await ChatService.getMessageIngame(this.id, createdAt);
        this.isLoadMore = Boolean(res.Data && res.Data.length);
        if (this.isLoadMore) {
          if (!createdAt) {
            this.items = res.Data.reverse();
            scrollToBottom("id-list-chat");
            return;
          }
          this.items = res.Data.reverse().concat(this.items);
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleScroll() {
      const ele = document.getElementById("id-list-chat");
      if (ele.scrollTop === 0 && this.isLoadMore) {
        this.getMessage(this.items[0].CreatedAt);
        ele.scrollTo({ top: 100, behavior: "smooth" });
      }
    },

    // MQTT
    onSubscribeMqttChat() {
      const topicMqttChat = `${process.env.VUE_APP_TOPIC_CHAT}/${this.id}`;
      this.$clientMqtt.subscribe(topicMqttChat);
    },

    // MQTT
    onUnSubscribeMqttChat() {
      const topicMqttChat = `${process.env.VUE_APP_TOPIC_CHAT}/${this.id}`;
      this.$clientMqtt.unsubscribe(topicMqttChat);
    },

    onListenMessageMqtt() {
      const topicMqttChat = `${process.env.VUE_APP_TOPIC_CHAT}/${this.id}`;
      this.$clientMqtt.on("message", async (topic, message) => {
        if (topicMqttChat != topic) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        const result = JSON.parse(decoder.decode(message));
        this.items.push(result);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-list-item-title) {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
::v-deep(.v-list-item-subtitle) {
  font-size: 12px;
  font-weight: 400;
  color: rgba(123, 114, 198, 1);
}
.cl-drawer {
  background: #241645;
  height: calc(100vh - 80px) !important;
  .cl-header {
    cursor: pointer;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background: #7b72c633;
  }
  .cl-action-bottom {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .cl-btn-send {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 8px;
      border-radius: 6px;
      background: #e44e4e;
      &:hover {
        background: rgba(228, 78, 78, 0.9);
      }
    }
  }
  .v-list {
    height: calc(100vh - 185px);
    background: rgba(36, 22, 69, 1) !important;
    border: none !important;
    border-bottom: 1px solid rgba(123, 114, 198, 0.2);
    overflow: auto;
    .cl-current-user {
      display: flex;
      justify-content: flex-end;
      .cl-content-user {
        width: max-content;
        max-width: 188px;
        min-height: 24px;
        padding: 5px 10px 5px 10px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.1);
      }
    }
    .cl-content-chat-mem {
      height: max-content;
      padding-bottom: 1px;
      line-height: 13px;
      text-align: left;
      color: #c4c4c4;
    }
  }
}
</style>