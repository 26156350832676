import routesName from './routesName';
import routesPath from './routesPath';

// component
import ProfileUser  from '@/modules/profile/index.vue';

export const routersProfile = [
  {
    name: routesName.PROFILE_USER,
    path: routesPath.PROFILE_USER,
    component: ProfileUser
  }
]