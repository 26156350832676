const entryPoint = `/users/api/v2.0/account`;
const entryPointDevelop = `developer/api/v2.0`;
const entryPointRanking = `${process.env.VUE_APP_GBATTLE_API}gbattle-ranking/api/v1.0`;
const entryPointLobby = `${process.env.VUE_APP_GBATTLE_API}gbattle/api/v1.0/lobby`;

export default {
  STEAM_CONFIG: `${entryPoint}/verifier/steam-config`,
  STEAM_ACCOUNT: `${entryPoint}/link-steam-account`,
  STEAM_VERIFIER: `${entryPoint}/verifier/steam`,
  GET_RANKING: `${entryPointRanking}/statistics/:game_code/ranking`,
  GET_HISTORY: `${entryPointRanking}/statistics/:game_code/user/history`,
  REPORT_USER: `${entryPointRanking}/statistics/report`,
  GET_LIST_SEASON: `${entryPointRanking}/list-season/:gameCode`,
  GET_BANNERS: `${entryPointDevelop}/banners/:pos`,

  // party
  CREATE_PARTY: `${entryPointRanking}/party`,
  UPDATE_PARTY: `${entryPointRanking}/party`,
  GET_INFO_PARTY: `${entryPointRanking}/party/info`,
  JOIN_PARTY: `${entryPointRanking}/party/join`,
  LEAVE_PARTY: `${entryPointRanking}/party`,
  INVITE_MEMBER: `${entryPointRanking}/party/invite`,
  KICK_MEMBER: `${entryPointRanking}/party/kick`,
  ACCEPT_INVITE: `${entryPointRanking}/party/accept`,
  REJECT_INVITE: `${entryPointRanking}/party/reject`,
  MATCH_MAKING: `${entryPointRanking}/party/match-making`,
  ACCEPT_MATCH_MAKING: `${entryPointRanking}/match`,
  CANCEL_MATCH_MAKING: `${entryPointRanking}/party/match-making`,
  LIST_PUBLIC_PARTY: `${entryPointRanking}/list-public-party`,
  START_GAME: `${entryPointRanking}/match/start-game`,
  GET_HISTORY_MATCH: `${entryPointRanking}/statistics/history-match/:game_id`,

  // lobby
  JOIN_LOBBY: `${entryPointLobby}/:game_code/join`,
  BAN_PICK_MAP_LOBBY: `${entryPointLobby}/:game_code/map/ban-pick/:match_id`,
  GET_LOBBY_INFO: `${entryPointLobby}/:game_code/:lobby_id`,
  GET_CURRENT_MATCH: `${entryPointLobby}/:game_code/current-match/:lobby_id`,
}