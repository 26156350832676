<template>
  <div class="cl-emoji">
    <button @click="showEmojiPicker">😊</button>
  </div>
</template>

<script>
// store
import { useStore } from "vuex";
import { key } from "@/store";
import { computed, watch } from "vue";

import { onMounted, onBeforeUnmount } from "vue";
import { EmojiButton } from "@joeattardi/emoji-button";

export default {
  name: "EmojiPicker",
  emit: ["addEmoji"],

  setup(props, { emit }) {
    let picker;
    const store = useStore(key);
    const drawerChat = computed(() => store.state.drawerChat);
    watch(drawerChat, (show) => {
      if (!show) {
        picker.hidePicker();
      }
    });

    const showEmojiPicker = (event) => {
      picker.showPicker(event.target);
    };

    const addEmoji = (selection) => {
      emit("addEmoji", selection.emoji);
    };

    onMounted(() => {
      picker = new EmojiButton({
        position: "top-start",
        autoHide: false,
      });
      picker.on("emoji", addEmoji);
    });

    onBeforeUnmount(() => {
      picker.off("emoji", addEmoji);
    });

    return {
      showEmojiPicker,
    };
  },
};
</script>

<style scoped>
.cl-emoji {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}
</style>
