export default {
  ROOT_PATH: '/',
  LOGIN_PATH: '/login',
  REGISTER_PATH: '/register',
  TYPE_SNACK_BAR: {
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning"
  },
  STATUS_USER: {
    OFFLINE: 0,
    ONLINE: 1,
    INGAME: 2,
    BUSY: 3
  },
  PARTY_MODE: {
    RANKING: "ranking",
    NORMAL: "normal"
  },
  PARTY_STATE: {
    opening: "opening",
    waiting: "waiting",
    found: "found",
    ready: "ready",
    gamingBan: "gaming_ban",
    gaming: "gaming",
    preFinish: "pre_finish",
    finish: "finish",
    reject: "reject",
    timeout: "timeout"
  },
  MAP_STATE: {
    open: 0,
    ban: 1,
    pick: 2
  },
  PLAYER_STATE: {
    init: 0,
    ready: 1,
    connectedGame: 2,
    disconnectGame: 3,
  },
  LOBBY_STATE: {
    init: 0,
    creatingServer: 1,
    running: 2,
    pause: 3,
  },
  TYPE_BOX: {
    allItem: 77,
    profile: 78,
    tools: 79,
    spells: 80,
    other: 81
  },
  SUB_CATEGORY: {
    avatar: 1110,
    frame: 1120,
    effect: 1140,
    avatarExclusive: 1361,
    frameExclusive: 1371,
    effectExclusive: 1373
  },
  COMPONET_PROFILE: {
    statistic: 0,
    inventory: 1,
    inforPlayer: 2
  },
  USER_ITEM_STATUS: {
    owned: 3,
    using: 5
  },
  MISSION_STATE: {
    Unfulfilled: 0,
    Progress: 1,
    Complete: 2,
    ReceivedAll: 3,
    ExpireTime: 4
  },
  EVENT_TIME_STATE: {
    HappeningTime: 1,
    OverTime: 2,
  }
}