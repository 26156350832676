<template>
  <v-dialog v-model="dialogItems" max-width="800" persistent>
    <div class="cl-dialog">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg-reward-items.svg"
        alt=""
      />
      <div class="flex w-full justify-between">
        <div class="w-85 h-85">
          <img :src="info.image" alt="" />
        </div>

        <div class="flex-1 relative flex flex-col items-center">
          <div class="cl-title">{{ info.title }}</div>
          <div class="w-full mt-9">
            <div class="text-white cl-font-14-bold">Tên người nhận</div>
            <v-text-field
              flat
              dirty
              autofocus
              color="#7B72C6"
              density="compact"
              variant="outlined"
              base-color="#7B72C6"
              bg-color="rgba(123, 114, 198, 0.3)"
              hide-details
            >
            </v-text-field>
            <div class="text-white mt-4 cl-font-14-bold">Địa chỉ nhận hàng</div>
            <v-text-field
              flat
              dirty
              color="#7B72C6"
              density="compact"
              variant="outlined"
              base-color="#7B72C6"
              bg-color="rgba(123, 114, 198, 0.3)"
              hide-details
            >
            </v-text-field>
            <div class="text-white mt-4 cl-font-14-bold">Số điện thoại</div>
            <v-text-field
              flat
              dirty
              color="#7B72C6"
              density="compact"
              variant="outlined"
              base-color="#7B72C6"
              bg-color="rgba(123, 114, 198, 0.3)"
              hide-details
            >
            </v-text-field>
          </div>
          <v-btn class="cl-btn-accept" @click="onAccept">
            <span class="cl-font-24-bold"> gửi </span>
          </v-btn>
        </div>
      </div>
      <!-- <v-btn
        width="32"
        height="32"
        class="absolute right-2 top-2"
        icon
        variant="plain"
        color="#7B72C6"
      >
        <v-icon size="32">mdi-close</v-icon>
      </v-btn> -->
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "dialogItems",
  emit: ["closeDialog"],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      dialogItems: this.value,
    };
  },

  watch: {
    dialogItems(val, oldVal) {
      if (val === oldVal) return;
    },

    value(val, oldVal) {
      if (val === oldVal) return;
      this.dialogItems = val;
    },
  },

  methods: {
    onAccept() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field input) {
  color: #ffffff !important;
  border-radius: 3px !important;
}

.cl-dialog {
  width: 800px;
  height: 450px;
  padding: 10px 40px;
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 250px;
    height: 60px;
    bottom: -20px;
    position: absolute;
  }
}
</style>