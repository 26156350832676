<template>
  <div class="cl-profile">
    <div class="flex">
      <div class="cl-frame mr-5">
        <Avatar
          v-if="userInfo?.avatar_url"
          :data="userInfo"
          :sizeAvatar="135"
          :sizeFrame="161"
          :tooltip="false"
        />
        <div v-else-if="loadingInfo" class="cl-img-avatar overflow-hidden">
          <v-skeleton-loader
            color="transparent"
            type="card"
          ></v-skeleton-loader>
        </div>
      </div>
      <div v-if="userInfo?.display_name" class="cl-account">
        <div class="cl-displayName">
          <div class="cl-gplay-name cl-font-36-bold">
            {{ userInfo?.display_name }}
          </div>
          <div
            v-if="userInfo?.steam_display_name"
            class="cl-steam-name cl-font-14-normal ml-n3 cursor-pointer"
            @click="showProfileSteam(userInfo?.steam_id)"
          >
            <v-icon size="16">mdi-steam</v-icon>
            <span>&nbsp;{{ userInfo?.steam_display_name }}</span>
          </div>
        </div>
        <div class="flex items-center my-1">
          <!-- TODO: svip -->
          <!-- <img
            class="mr-5"
            src="@/assets/images/icons/s_vip.svg"
            alt=""
            srcset=""
          /> -->
          <div v-if="userInfo?.rank > 0" class="cl-point">
            <div class="cl-ranking">
              <img
                class="cl-img-ranking w-26 h-9.5"
                :src="userInfo?.rank_background"
                alt=""
              />
              <div
                class="cl-txt-rank cl-font-20-normal maxline1"
                :style="{ color: userInfo?.elo_color }"
              >
                {{ userInfo?.elo }}
              </div>
            </div>
            <!-- TODO: rank -->
            <div class="cl-cup">
              <img
                class="cl-img-ranking"
                src="@/assets/images/remove/point_rank.svg"
                alt=""
              />
              <div class="cl-txt-rank cl-font-16-bold maxline1">
                {{ userInfo?.rank }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center">
      <v-btn
        v-if="isShowBtnFriends()"
        class="mr-2 mt-n1"
        color="success"
        @click="onAddFriends()"
      >
        <span class="normal-case cl-font-16-bold">
          <v-icon>mdi-account-plus-outline</v-icon> Kết bạn
        </span>
      </v-btn>
      <div class="cl-profile-right cl-font-14-normal">
        <span class="cl-txt-time cl-font-18-bold">{{ getTimePlayer() }}</span>
        &nbsp;Giờ chơi
      </div>
    </div>
  </div>
</template>

<script>
import constants from "@/constants";

// mixins
import ranking from "@/mixins/ranking";
import steamConnect from "@/mixins/steamConnect";

// sotorage
import { getUserInfo } from "@/helpers/storage";

// service
import { AuthService } from "@/services/authService";

// component
import Avatar from "@/components/core/Avatar.vue";

export default {
  mixins: [ranking, steamConnect],
  components: {
    Avatar,
  },

  props: {
    userInfo: {
      type: Object,
      default: null,
    },
    loadingInfo: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isShowBtnFriends() {
      const currentUser = JSON.parse(getUserInfo());
      if (!this.userInfo) {
        return false;
      }
      return (
        currentUser.user_id !== this.userInfo?.user_id &&
        !this.userInfo?.is_friend
      );
    },

    async onAddFriends() {
      try {
        await AuthService.addFriend({
          FriendId: this.userInfo?.user_id,
        });
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t("drawer_friends.add_friend_success", {
            name: this.userInfo?.display_name,
          }),
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },

    getTimePlayer() {
      const seconds = this.userInfo?.time_play ?? 0;
      if (seconds < 3600) {
        return 0;
      }
      return parseInt(Math.floor(seconds / 3600));
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-profile {
  width: calc(100vw - 70px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: -122px 0px 0px 65px;
  .cl-profile-right {
    width: max-content;
    height: 44px;
    padding: 0px 10px 0px 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    .cl-txt-time {
      color: rgba(230, 186, 32, 1);
    }
    .cl-txt-space {
      height: 34px;
      width: 1px;
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .cl-frame {
    width: 161px;
    height: 161px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .cl-img-frame {
      width: 161px;
      height: 161px;
      z-index: 1;
      border-radius: 100%;
    }
    .cl-img-avatar {
      width: 135px;
      height: 135px;
      border-radius: 100%;
      position: absolute;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cl-account {
    display: flex;
    gap: 5px;
    justify-content: center;
    flex-direction: column;
    margin-top: -8px;
    .cl-point {
      width: 220px;
      height: 34px;
      display: flex;
      align-items: center;
      position: relative;
      .cl-img-ranking {
        position: absolute;
        z-index: -1;
      }
      .cl-txt-rank {
        width: 70px;
        margin-top: 2px;
        text-align: center;
        font-style: italic;
        font-weight: 700;
        line-height: 40px;
        color: #ffffff;
      }
      .cl-ranking {
        width: max-content;
        .cl-img-ranking {
          left: 0px;
          top: -4px;
        }
      }
      .cl-cup {
        width: max-content;
        .cl-img-ranking {
          right: 0px;
          top: 0px;
        }
        .cl-txt-rank {
          margin-left: 80px;
          color: rgba(134, 42, 45, 1);
        }
      }
    }
    .cl-displayName {
      width: max-content;
      height: 44px;
      display: flex;
      align-items: center;
      .cl-steam-name {
        width: max-content;
        height: 24px;
        padding: 0px 10px 1px 3px;
        border-radius: 16px;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        background: rgba(40, 40, 40, 1);
        border: 1px solid rgba(136, 136, 136, 1);
      }
      .cl-gplay-name {
        height: 44px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 0px 50px 0px 10px;
        border-radius: 20px 0px 0px 20px;
        border: 2px solid transparent;
        border-right: 0px;
        background: linear-gradient(90deg, #282828 0%, rgba(40, 40, 40, 0) 100%) padding-box,
            linear-gradient(90deg,  #444444 50%, rgba(68, 68, 68, 0) 100%) border-box;
      }
    }
  }
}
</style>
